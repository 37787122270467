

//ERROR MESSAGES
var requiredFieldsMessage = '<h1>There are required fields which are missing.</h1><br><p>Please check and complete them.</p>';
var waitForUploadFileMessage = '<h1>Please wait for the items to be uploaded.</p>';
var errorMessage = '<h1>There has been an error. Please reload the page and try again.</h1>';
var formLoadOkMessage = '<h1>The data has been successfully retrieved.</h1>';
var editionNotAllowedMesssage = '<h1>You are not allowed to edit this form.</h1>';
var formidinvalid = '<h1>Form is Invalid.</h1>';
var fileSizeLimitMessage = '<h1>Unable to upload the file. The filesize must be lower than 100MB.</h1>';
var formisRemoved = "<h1>Requested form no longer exists in the application.</h1>";

var fileNameEmpty = '<h1>The file cannot be empty.</h1>';
var fileNameLongerThan128 = '<h1>The filename must be shorter than {0} characters.</h1>';
var fileNameInvalidCharacters = '<h1 A file name cannot contain any of the following characters: <br>\\ / : * ? " < > | # { } % ~ &</h1>';
var docErrorMessage = '<h1>File can not be Encrypted.</h1>';

var fileUploading = '<h1>Unable to attach the selected file. The upload of a file with the same name is already in progress.</h1>';
var fileSizeInvalid = '<h1>The upload of a file with the invalid size</h1>';
var removeFileWarningMessage = '<h1>Are you sure you want to remove the attachment?</h1>';
var fileWithSameNameMessage = '<h1>A file with that name already exists.<br> Do you wish to overwrite it?</h1>';
var fileWithSameNameBlockedMessage = '<h1>A file with the same name already exists in the list of attached documents.</h1>';
var missingCommentsRework = '<h1>Before returning the form, you need to add a comment.</h1>';

var notAllowedToCreateForms = '<h1>You are not allowed to create forms.</h1>';
var documentReviewProcess = '<h1>The Document Review Process has been completed.</h1>';

var invalidFieldsMessage = '<h1>There seems to be invalid fields.</h1><br><p>Please review.</p>';
var missingCommentsReviewForRework = '<h1>Before returning for rework the document type, you need to add a comment.</h1>';
var additinalQRMComplianceReviewNeeded = '<h1>You must select "Reviewed" if Additional QRM/Compliance Review is selected.</h1>';
var textFieldLongerThan255 = '<h1>The single text fields must be shorter than 255 characters.</h1>'


var discontinueFormMessage = '<h1>Are you sure you want to discontinue the form?</h1>';
var discontinueReasonNeeded = '<h1>Before discontinuing the form, you need to provide a reason.</h1>';

var putOnHoldFormMessage = '<h1>Are you sure you want to put the form on hold?</h1>';
var putOnHoldReasonNeeded = '<h1>Before putting the form on hold, you need to provide a reason.</h1>';

var reactivateFormMessage = '<h1>Are you sure you want to reactivate this form?</h1>';

var updatedFormMessage = '<h1>This form has been updated.</h1>';

var markAsFinalVersionMessage = '<h1>Are you sure you want to mark this document as the final version?</h1>';

var submittingWithoutRequiredFinalVersions = '<h1>At least one document type still requires a "final version" document. Please review them and try again.</h1>';
var saveMessage = '<h1>The data has been successfully saved.</h1>';

var popupImage = "<h1>Looks like your browser settings are blocking some functionality of the DCF application.Please allow the pop up as shown below & refresh the page.</h1><br> <img  style='width: 250px;padding-left: 0;'  src='https://americas.internal.deloitteonline.com/sites/dcfforms/crossborderform/SiteAssets/pop.png' alt='Test Data'>";

function confirmModalAction(funcOK, funcCancel) {
    $('body').css('overflow','hidden');
    $('#confirm-modal button.confirmation-modal-button').on('click', function (e) {
        $('#confirm-modal button.confirmation-modal-button').off('click')
        $('#confirm-modal button.close-modal').off('click')
        $('body').css('overflow','auto');
        funcOK();
    })
    $('#confirm-modal button.close-modal').on('click', function (e) {
        $('#confirm-modal button.confirmation-modal-button').off('click')
        $('#confirm-modal button.close-modal').off('click')
        $('body').css('overflow','auto');
        if (typeof (funcCancel) != 'undefined') {
            funcCancel()
        }
    })
    $('#confirm-modal').modal('show')
}

function confirmModalWithReasonAction(modalType, funcOK, funcCancel) {
    $('body').css('overflow','hidden');
    var confirmationModalSelector;
    var closeModalSelector;
    var buttonSelector;
    if (modalType === 'discontinue') {
        confirmationModalSelector = $('#discontinue-with-reason-modal button.confirmation-modal-button');
        closeModalSelector = $('#discontinue-with-reason-modal button.close-modal');
        buttonSelector = $("#discontinue-with-reason-button");
    }
    if (modalType === 'putOnHold') {
        confirmationModalSelector = $('#put-on-hold-with-reason-modal button.confirmation-modal-button');
        closeModalSelector = $('#put-on-hold-with-reason-modal button.close-modal');
        buttonSelector = $("#put-on-hold-with-reason-button");
    }
    confirmationModalSelector.on('click', function (e) {
        $('body').css('overflow','auto');
        funcOK();
    })
    closeModalSelector.on('click', function (e) {
        confirmationModalSelector.off('click');
        closeModalSelector.off('click');
        $('body').css('overflow','auto');
        if (typeof (funcCancel) != 'undefined') {
            funcCancel()
        }
    })
    buttonSelector.click();
}

function getDiscontinueModalBody() {
    return discontinueFormMessage +
        '<span id="discontinue-reason-label">Reason:</span>' +
        '<textarea id="DiscontinueReason" name="DiscontinueReason" rows="3" class="input-data-text text-area-style"></textarea>';
}

function getPutOnHoldModalBody() {
    return putOnHoldFormMessage +
        '<span id="discontinue-reason-label">Reason:</span>' +
        '<textarea id="PutOnHoldReason" name="PutOnHoldReason" rows="3" class="input-data-text text-area-style"></textarea>';
}

function setMessageDiscontinueFormAction() {
    $('body').css('overflow','hidden');
    $("#discontinue-with-reason-modal .modal-body")[0].innerHTML = getDiscontinueModalBody();
}

function setMessagePutOnHoldFormAction() {
    $('body').css('overflow','hidden');
    $("#put-on-hold-with-reason-modal .modal-body")[0].innerHTML = getPutOnHoldModalBody();
}


// function showModalMessage(message){
// $(".modal-body")[0].innerHTML = message;
// clickSubmitModalButton();
// }

function showModalMessage(message) {
    //For Onload console errors restricting hidden Property
    if(message!="<h1>There has been an error. Please try again</h1>"){
      $('body').css('overflow','hidden');
    }
    $(".modal-body")[0].innerHTML = message;
   
    clickSubmitModalButton();
}

function showPopupBlockerMessage() {

    $(".modal-body")[0].innerHTML = popupImage;

    clickSubmitModalButton();
}

function getConfirmationMessage() {
    $('body').css('overflow','hidden');  
    switch (gblFormStatus) {
        case "Approved":
            return '<h1>The form has been approved.</h1>';
            break;
        case "Rejected":
            return '<h1>The form has been returned for rework.</h1>';
            break;
        case "Submitted":
            return '<h1>The form has been submitted.</h1>';
            break;
        case "Draft":
            return '<h1>The data has been successfully saved.</h1>';
            break;
        case "Discontinued":
            return '<h1>The form has been discontinued.</h1>';
            break;
        case "Reviewed":
            return '<h1>The Document Review Process has been completed.</h1>';
            break;
        case "OnHold":
            return '<h1>The form has been put on hold.</h1>';
            break;
    }
}

function redirectOkModal() {
    window.location.href=appUrl+"crossborderform?item_id="+xbFormId;
}