var reviewedStatus = "Reviewed";
var reviewInProgressStatus = "Review in Progress";
var briefingPaperDocumentTypeId = "2";
var maxTemplates = 4;
var additionalReviewNeededYes = "Yes";
var additionalReviewNeededNo = "No";
var additionalReviewNeededNA = "N/A";
$('#s4-workspace').css("pointer-events","none");//Added to restrict editing the form until completion of loading.
//retrieve review status

function generateHtmlIcon(documentType,status,role,iconClass) {
    var html= '<span class="review-assistant-icon-status check-status ' + iconClass + '">'+
        '<button type="button" status="' + status + '" hidden role="' + role + '" document-type-icon-status="' + documentType +'"></button>'+
    '</span>'
    return html;
}

function replaceIconReviewStatus(htmlIcon,columnReviewStatus) {
    columnReviewStatus.children("span").remove();
    columnReviewStatus.append(htmlIcon);
}

function addIconToSection(reviewStatus, documentType) {
    var documentTypeInfo = {
       'Reviewed': { classIcon: 'fa fa-check-circle reviewed-icon-color'},
       'Pending': { classIcon: 'fa fa-clock-o pending-icon-color pending-review-icon'},
       'Returned For Rework': { classIcon: 'fa fa-times-circle return-review-icon return-for-rework-icon-color'},
       'N/A': { classIcon: 'glyphicon glyphicon-log-out review-na-icon-color'}
    }

    for (i = 0; i < reviewStatus.length; ++i) {
        if (reviewStatus[i].status) {
            var status = reviewStatus[i].status;
            var role = reviewStatus[i].role;
            var columnReviewStatus = reviewStatus[i].columnReviewStatus;
            var iconClass = documentTypeInfo[status].classIcon;
            var htmlIcon = generateHtmlIcon(documentType,status,role,iconClass);
            replaceIconReviewStatus(htmlIcon,columnReviewStatus);
        }
    }
}

function populateStatusIconDocumentType(data) {
    var reviews = data;
    for (var review in reviews) {
        var documentTypeId;
        if(reviews[review].BusinessReviewStatus!=null || reviews[review].ComplianceReviewStatus!=null || reviews[review].QRMReviewStatus!=null)
        {
        if(documentTypeList != undefined && documentTypeList.length > 0)
        {
           var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeTitle == reviews[review].DocumentType);
           if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
               documentTypeId = docTypeObj[0].DocumentTypeId;
        }
       // var documentTypeId =1;// reviews[review].DocumentType.Id;
       var reviewStatusId = [
        {
            id: "1",
            status:"Reviewed"
        },
        {
            id: "2",
            status:"Pending"
        },
        {
            id: "3",
            status:"Returned For Rework"
        },
        {
            id: "4",
            status:"N/A"
        }

    ];
    var reviewId;
    if(reviewStatusId != undefined && reviewStatusId.length > 0)
        {

            var reviewStatus=""
            if(reviews[review].QRMReviewStatus!="" && reviews[review].QRMReviewStatus!=undefined &&reviews[review].QRMReviewStatus!="-")//this is a temporary fix, we need to clear the data in dyanmo db for the column till then filtering such values 
            reviewStatus=reviews[review].QRMReviewStatus;
            else if(reviews[review].BusinessReviewStatus!="" && reviews[review].BusinessReviewStatus!=undefined&&reviews[review].QRMReviewStatus!="-")//this is a temporary fix, we need to clear the data in dyanmo db for the column till then filtering such values
            reviewStatus=reviews[review].BusinessReviewStatus;
            else
            reviewStatus=reviews[review].ComplianceReviewStatus;

           var docTypeObj = reviewStatusId.filter(obj => obj.status == reviewStatus);
           if(docTypeObj != undefined && docTypeObj != null)
           reviewId = docTypeObj[0].id;
        }

       
        var additionalReviewNeeded = reviews[review].AdditionalReviewNeeded;
        $(".review-status-row-document-type-" + documentTypeId).attr("review-item-id",reviewId);
        $(".review-status-row-document-type-" + documentTypeId).attr("additional-review-needed",additionalReviewNeeded); 
        var documentType = $('[id=document-type-' + documentTypeId + ']');
        var row = documentType.closest("div.row");
        var reviewStatus = [
            {
                role: "Business",
                columnReviewStatus: row.find(".businessReviewStatus").parent(),
                status: reviews[review].BusinessReviewStatus
            }, {
                role: "QRM",
                columnReviewStatus: row.find(".qrmReviewStatus").parent(),
                status: reviews[review].QRMReviewStatus
            }, {
                role: "Compliance",
                columnReviewStatus: row.find(".complianceReviewStatus").parent(),
                status: reviews[review].ComplianceReviewStatus
            }
        ];
        addIconToSection(reviewStatus, documentTypeId);
    }
}
}

function getDocumentTypes(isRequired) {
    var deferred = $.Deferred();
    $.ajax({
        url: apiUrl + "CrossBorderDocumentTypes/GetList",
        method: "GET",
        contentType: "application/json;odata=verbose",
        headers: {
            "Accept": "application/json;odata=verbose",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            
            // var required="FALSE";
            // if(isRequired==1)
            //     required="TRUE"
            
            // data = data.filter(obj=>obj.Required==required);
            deferred.resolve(data);
           
        },
        error: function (error) {
            showError(error);
        }
    });
    return deferred.promise();
}

function getFormAdditionalDocumentTypes(formId) {   
    return $.ajax({
        url: apiUrl + "CrossBorderAdditionalDocumentTypes/GetList?formID="+formId,
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
    });
}
var  documentTypeList=[];
function sortObjects(a, b) {
    return (+a.DocumentTypeOrder) - (+b.DocumentTypeOrder);
}
//fill the document types table
function populateDocumentTypes(formStatus,atachments){
    var deferred = $.Deferred();
    var isRequired = 1;
    $.when(getDocumentTypes(), getRecommendedTemplates(),getFormAdditionalDocumentTypes(gblFormId)).done(function(documentdata, recommendedTemplateData,additionaldocumentType){
        var isAdditionalDocumentType = false;
        documentObj=documentdata;
        var documentRequired= documentObj.filter(x =>x.Required == "TRUE");
        //handling schedule3 document for both old forms and new forms
        var data = documentObj.filter(x =>x.Required == "TRUE");
        
        if(!gblSchedule3DocumentRequired && !hasSchedule3Attachment)
        data = data.filter(x => x.DocumentTypeTitle !== documentSchedule3Object.documentTypeTitle && x.Required == "TRUE");
        
        data=data.sort(function(a, b) {
            return a.DocumentTypeOrder - b.DocumentTypeOrder;
            });

        // to get document type id in generate attachment function
        

    for (var key in data) {
        var documentTypeTitle = data[key].DocumentTypeTitle;
        var documentTypeId = data[key].DocumentTypeId;
        var internalId = data[key].DocumentTypeId;
        var requiresFinalVersion = data[key].RequiresFinalVersion;
        var guidance = data[key].Guidance;
        var businessReviewApplies =  data[key].BusinessReviewApplies;
        var qrmReviewApplies =  data[key].QRMReviewApplies;
        var complianceReviewApplies =  data[key].ComplianceReviewApplies;
        var dependentDocumentTypes = data[key].DependentDocumentTypes;

        //check for inactive document types and hide if no attachment is present
        // if(data[key].isActive!= undefined && data[key].isActive == "No")
        // {
        //     if(isAttachementPresent(documentTypeTitle,atachments))
        //     {
        //         generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, isAdditionalDocumentType);    
        //     }
        // }
        // else
        // {
        //     generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, isAdditionalDocumentType);
        // }
        generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, isAdditionalDocumentType);
    }
        hideOpenBoxes();
        $.when(populateAdditionalDocumentTypes(data,recommendedTemplateData,documentObj,additionaldocumentType[0],atachments)).done(function(){
            addNotificationIconToTemplateIcon(recommendedTemplateData);
            deferred.resolve();
        });
       
    });
    return deferred.promise();
}

function isAttachementPresent(documentTypeTitle,atachments)
{
   var attachmentExists = false;
   if(atachments!= undefined && atachments.length > 0)
   {
      var filteredAttachments = atachments.filter(x=>x.DocumentType == documentTypeTitle);
      if(filteredAttachments!=undefined && filteredAttachments!=null && filteredAttachments.length > 0)
      {
        attachmentExists = true;
      }
   }
   return attachmentExists;
}

function addNotificationIconToTemplateIcon(recommendedTemplateDataResults) {
        for (var recommended in recommendedTemplateDataResults) {
            var docType = documentTypeList.filter(obj=>obj.DocumentTypeTitle == recommendedTemplateDataResults[recommended].DocumentType)
            if(docType!=undefined && docType!=null && docType.length>0)
            {
                var documentTypeRecommendedTemplate = docType[0].DocumentTypeId;
                replaceHtmlRecommendedTemplateIcon(documentTypeRecommendedTemplate);
            }
        }
}

function populateAdditionalDocumentTypes(data,recommendedTemplateData,documentObj,additionalData,atachments) {
    var formId = GetURLParameter('item_id');
    var requiredDocumentTypes = data;
    var additionalDocumentTypes;
    var isRequired = 1;
    var isNotRequired = 0
    var deferred = $.Deferred();
    if (formId) {
        //$.when(getFormAdditionalDocumentTypes(formId)).done(function(additionalData){
            var requiredDocumentList = documentObj.filter(x =>x.Required == "TRUE");
            var noRequiredData=documentObj.filter(x =>x.Required == "FALSE");
            var additionalDocumentTypes =additionalData;

            documentTypeList = documentTypeList.concat(noRequiredData);
            documentTypeList = documentTypeList.concat(requiredDocumentList);
            
            additionalDocumentTypes = cleanRequiredDocumentTypes(additionalDocumentTypes,requiredDocumentTypes);
            var filteradditionalDocumentTypes = additionalDocumentTypes.filter(obj=>obj.FormID == formId);
            //For Old forms if column schedule3DocumentRequired is not required then add schedule3 document into additionalDocumentTypes
            var schedule3Document = requiredDocumentList.filter(x => x.DocumentTypeTitle === 'Schedule 3: US Potential Counterparties')[0];
            var hasSchedule3Document = requiredDocumentTypes.some(x => x.DocumentTypeTitle === 'Schedule 3: US Potential Counterparties');
            var hasSchedule3InAdditionalDocument = filteradditionalDocumentTypes.some(x => x.DocumentType == 'Schedule 3: US Potential Counterparties');

            // ==> !!! expression returns true if schedule3Document is null
            if (hasSchedule3InAdditionalDocument && !hasSchedule3Document)
                  noRequiredData.push(schedule3Document);

            if (filteradditionalDocumentTypes.length > 0) {
                var isAdditionalDocumentType = true;
                var guidance = "";
                var documentTypeId;
                var internalId;
                var dependentDocumentTypes;
                var requiresFinalVersion;
                var documentTypeTitle;
                for (var key in filteradditionalDocumentTypes) {
                    documentTypeTitle='';
                    documentTypeId='';
                    internalId='';
                    dependentDocumentTypes='';
                    requiresFinalVersion='';
                    if(noRequiredData != undefined && noRequiredData.length > 0)
                    {
                        var docTypeObj = noRequiredData.filter(obj => obj.DocumentTypeTitle == filteradditionalDocumentTypes[key].DocumentType);
                        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0){
                            documentTypeId = docTypeObj[0].DocumentTypeId;
                            internalId = docTypeObj[0].ID;
                            dependentDocumentTypes = docTypeObj[0].DependentDocumentTypes;
                            requiresFinalVersion = docTypeObj[0].RequiresFinalVersion;
                            documentTypeTitle = docTypeObj[0].DocumentTypeTitle;
                        }
                    }

                    // var internalId = filteradditionalDocumentTypes[key].DocumentTypeId;
                    // var dependentDocumentTypes = filteradditionalDocumentTypes[key].DependentDocumentTypes;
                    // var requiresFinalVersion = filteradditionalDocumentTypes[key].RequiresFinalVersion;
                    // var additionalDocumentTypeData = noRequiredData.filter(function(element) {
                    //     return element.ID == documentTypeId;
                    // });
                    var additionalDocumentTypeData = noRequiredData.filter(obj=>obj.DocumentTypeTitle==documentTypeTitle)
                    if(additionalDocumentTypeData.length > 0)
                    {
                        var businessReviewApplies =  additionalDocumentTypeData[0].BusinessReviewApplies;
                        var qrmReviewApplies =  additionalDocumentTypeData[0].QRMReviewApplies;
                        var complianceReviewApplies =  additionalDocumentTypeData[0].ComplianceReviewApplies;
                        //check for inactive document types and hide if no attachment is present
                        // if(additionalDocumentTypeData[0].isActive!= undefined && additionalDocumentTypeData[0].isActive == "No")
                        // {
                        //     if(isAttachementPresent(documentTypeTitle,atachments))
                        //     {
                        //         generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, isAdditionalDocumentType);
                        //     }
                        // }
                        // else
                        // {
                        //     generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, isAdditionalDocumentType);
                        // }   
                        generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, isAdditionalDocumentType);     
                     }
                }
                hideOpenBoxes();
                updateGuidanceForAdditionalDocumentTypes(filteradditionalDocumentTypes, noRequiredData);
                addNotificationIconToTemplateIcon(recommendedTemplateData);
            }
            deferred.resolve();  
       // });
    }
    return deferred.promise();
}

function updateGuidanceForAdditionalDocumentTypes(additionalDocumentTypes, noRequiredData) {
    var noRequired = noRequiredData;
    for (var key in noRequired) {
        var currentType = noRequired[key].DocumentTypeTitle;
        var isAdditionalDocumentTypeAdded = additionalDocumentTypes.some(function (objVal) {
            return objVal.DocumentType == currentType;
        });
        if (isAdditionalDocumentTypeAdded) {
            var guidance = noRequired[key].Guidance;
            var internalId = noRequired[key].DocumentTypeId;
            if (isInvalidGuidance(guidance, internalId)) {
                $("#required-DocumentTypes").find("[document-internal-id='" + internalId + "']").siblings(".tooltiptext-dcf").addClass("display-none");
            } else {
                $("#required-DocumentTypes").find("[document-internal-id='" + internalId + "']").siblings(".tooltiptext-dcf").removeClass("display-none");
                $("#required-DocumentTypes").find("[document-internal-id='" + internalId + "']").siblings(".tooltiptext-dcf").html(guidance);
            }
        }
    }
}

function cleanRequiredDocumentTypes(additionalDocumentTypes,requiredDocumentTypes) {
    var filteredDocumentTypes = additionalDocumentTypes.filter(function(additionalDocumentTypeElement){
        return requiredDocumentTypes.filter(function(requiredDocumentTypeElement){
            return requiredDocumentTypeElement.DocumentTypeTitle == additionalDocumentTypeElement.DocumentType;
        }).length == 0;
    });
    return filteredDocumentTypes;
}

function cleanAdditionalDocumentTypes(additionalDocumentTypes,documentTypesToRemove) {
    var filteredDocumentTypes = additionalDocumentTypes.filter(function(additionalDocumentTypeElement){
        return documentTypesToRemove.filter(function(documentTypeElement){
            return documentTypeElement == additionalDocumentTypeElement.DocumentTypeTitle;
        }).length == 0;
    });
    return filteredDocumentTypes;
}

function isInvalidGuidance(guidance, internalId) {
    return typeof(guidance) == 'undefined' || guidance == null || guidance == "null" || guidance.trim() == "";
}

function generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, _businessReviewApplies, _qrmReviewApplies, _complianceReviewApplies, dependentDocumentTypes, _requiresFinalVersion, guidance, isAdditionalDocumentType){
    var htmlData;
    var requiresFinalVersion,complianceReviewApplies,qrmReviewApplies,businessReviewApplies = true;
    if(_complianceReviewApplies=="TRUE" || _complianceReviewApplies=="true" )
        complianceReviewApplies = true;
         else
         complianceReviewApplies = false;
    if(_qrmReviewApplies=="TRUE" || _qrmReviewApplies=="true")
    qrmReviewApplies = true;
         else
         qrmReviewApplies = false;
    if(_requiresFinalVersion=="TRUE" || _requiresFinalVersion=="true")
         requiresFinalVersion = true;
         else
         requiresFinalVersion = false;

         if(_businessReviewApplies=="TRUE" || _businessReviewApplies=="true")
            businessReviewApplies = true;
         else
         businessReviewApplies = false;
    htmlData = '<div class="row no-padding-left-right '+ ( requiresFinalVersion === true ? 'requires-final-version' : '' ) +' "><div class="col-xs-12"><div class="col-xs-7"><div class="col-xs-6 align-center"><button type="button" onmouseover="enableTooltip($(this))" class="btn btn-sm btn-primary tooltip-dcf '+ ( requiresFinalVersion === true ? 'requires-final-version' : '' ) +' open-selector-file document-type-button width-85 no-cursor-pointer" document-internal-id="' + internalId + '" document-title="' + documentTypeTitle +
            '" business-review-applies=' + businessReviewApplies + ' qrm-review-applies=' + qrmReviewApplies + ' compliance-review-applies=' + complianceReviewApplies + ' additional-document-type="' + isAdditionalDocumentType + '">' + documentTypeTitle + '</button>' + generateTooltipForDocumentType(guidance, internalId) + generateFinalVersionReminder(requiresFinalVersion) +
            '<input type="file" class="hidden" document-required="true" additional-document-type="' + isAdditionalDocumentType + '" document-type-title="' + documentTypeTitle + '" id="document-type-' + documentTypeId +  '" document-type="' + documentTypeId + '">' + 
            '<input type="text" class="name-file hidden" disabled="true"></div><div class="col-xs-6">' + 
            '<div data-redline="false" id="attachment-section-document-type-' + documentTypeId + '"><ul class="file-list ul-no-style document-name-padding"></ul></div><div class="redline-section redline-attachment-section-' + documentTypeId + 
            '"><ul class="file-list ul-no-style document-name-padding"></ul></div></div></div><div class="col-xs-5"><div class="col-xs-8 bottom-3 review-status-row-document-type-' + documentTypeId + '" review-item-id="">' +
            '</div><div class="col-xs-4 align-center template-icon-' + documentTypeId + ' "><a href="/" onclick="return false;"><div class="glyphicon glyphicon-file open-templates"></div></a></div></div></div><div class="hidden" id="comment-section-' + documentTypeId + '"></div></div><hr>';
    if (htmlData != '') {
        $('#required-DocumentTypes').append(htmlData);
    }
}

function generateFinalVersionReminder(required) {
    if (required=='TRUE'){
        return "<div class='requires-final-version-label'><span>* Requires final version *</span></div>";
    }
    return "";
}

function generateTooltipForDocumentType(guidance, internalId) {
    if (isInvalidGuidance(guidance, internalId)) {
        return '<span class="tooltiptext-dcf display-none"></span>';
    }
    return '<span class="tooltiptext-dcf">' + guidance + '</span>';
}

function getDocumentStatus(formId) {
    var deferred = $.Deferred();
    var jsonItem = {};
    jsonItem['FormID'] = formId; 
    jsonItem['DocumentType'] = '';
    $.ajax({
        url: apiUrl+"CrossBorderApprovalReviewStatus/GetList",
        type: "POST",
        data: JSON.stringify(jsonItem),
        headers: {
            "accept": "application/json;",
            "content-Type": "application/json",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            showError(error);
        }
    });
    return deferred.promise();
}

//validate review

function apiPutReviewerStatus(formId, reviewersStatus, QRMComplianceNotificationNeeded) {
    //UpdateFormDigest(siteRelativeUrl, _spFormDigestRefreshInterval) 
    $('body').css('overflow','auto');  
    var jsonItem = {};

    var jsonItem = {}
    if (gblFormStatus != 'Draft' && !autosavedExecuted) {
        jsonItem['FormStatus'] = gblFormStatus;
       
    }
    if(gblFormId!=""&&gblFormId!=undefined)
    {
        jsonItem['ID'] = gblFormId;
        jsonItem['Created'] = CreatedDate;
        jsonItem['FormActionGroup'] = gblFormGroup;
        jsonItem['FormStatus'] = gblFormStatus;
        
    }
    if(gblFormGroup=="MemberFirmTeam" && gblFormStatus=="Submitted")
    {
        jsonItem['FormActionGroup'] = "IndustryRepresentative";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="IndustryRepresentative" && gblFormStatus=="Rejected")
    {
        jsonItem['FormActionGroup'] = "MemberFirmTeam";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="IndustryRepresentative" && gblFormStatus=="Approved")
    {
        jsonItem['FormActionGroup'] = "SecondSubmissionTeam";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="SecondSubmissionTeam" && gblFormStatus=="Submitted")
    {
        jsonItem['FormActionGroup'] = "BusinessQRMCompliance";
        jsonItem['FormStatus'] = "Pending";  
    }
    if(gblFormGroup=="Series24" && gblFormStatus=="Rejected")
    {
        jsonItem['FormActionGroup'] = "Series24";
        jsonItem['FormStatus'] = "Rejected";  
    }


    $.each($(".input-data-date"), function () {
        var inputdata = $(this);
        var inputVal = inputdata.val();
        if (inputVal != '') {
            dateToSent = new Date(inputdata.attr("approvalDate"));
        }
        else {
            dateToSent = null;

        }
        jsonItem[inputdata.attr('name')] = dateToSent;
    })

    $.each($(".input-data-text"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
    })

    $.each($(".input-data-float"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val()
    })

    $.each($(".input-data-integer"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val()
    })

    $.each($(".input-data-currency"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
       // jsonItem[inputdata.attr('name')] = formatNumberToSend(inputdata.val(), false);
    })

    //go over all checkboxes section
    $.each($(".checkbox-section"), function () {
        var CheckedResults = [];
        var checkBoxSection = $(this);

        $.each(checkBoxSection.find($(".input-data-checkbox")), function () {
            var inputdata = $(this);
            if (inputdata.is(':checked')) {
                CheckedResults.push(inputdata.val());
            }
        })
        jsonItem[checkBoxSection.attr('id')] = CheckedResults;
    })

    //go over all radios
    $.each($(".input-data-option"), function () {
        var inputdata = $(this);
        if (inputdata.is(':checked')) {
            jsonItem[inputdata.attr('name')] = inputdata.val();
        }
        else {
            if (!jsonItem[inputdata.attr('name')]) {
                jsonItem[inputdata.attr('name')] = null;
            }
        }
    })

    //go over all selects
    $.each($(".input-data-select"), function () {
        var inputdata = $(this);
        jsonItem[inputdata.attr('name')] = inputdata.val();
    })

    var pUsers = [];
    var arr = []
    var arrName = []
    $('#DCFEngagementTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        arr.push( $(item).attr('email'))
        arrName.push(item.innerText)
    })
    pUsers["DCFEngagementTeam"] = arr;

   
    var bUsers = [];
    var l = []
    var DealTeamBusinessReviewerDisplayNames=[];
    $('#DealTeamBusinessReviewer_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        l.push($(item).attr('email'))
        DealTeamBusinessReviewerDisplayNames.push(item.innerText);
    })
    bUsers["DealTeamBusinessReviewer"] = l;
    var m = []
    var memberFirmTeamDisplayNames = []
    $('#peoplePickerDivMemberFirmTeam_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        m.push($(item).attr('email'));
        memberFirmTeamDisplayNames.push(item.innerText);
    })
    jsonItem["InternationalMemberFirmTeam"]=m
    jsonItem["InternationalMemberFirmTeamDisplayNames"]=memberFirmTeamDisplayNames
    jsonItem["DealTeamBusinessReviewer"]=l
    jsonItem["DealTeamBusinessReviewerDisplayNames"]=DealTeamBusinessReviewerDisplayNames
    jsonItem["DCFEngagementTeam"]=arr;
    jsonItem["DCFEngagementTeamDisplayNames"]=arrName;
    jsonItem['CreatedBy']=CreatedBy;
    jsonItem['ModifiedBy']=localStorage.getItem("userProfileName");

    var sUsers = [];
    var sl = [];
    var Series24DisplayNames=[];
    $('#Series24_chosen .chosen-choices .search-choice').each(function (i, item) {
        //var lidata=$(this).find('.search-choice-close');
        sl.push($(item).attr('email'));
        Series24DisplayNames.push(item.innerText);
    })
    jsonItem["Series24"]=sl;
    jsonItem["Series24DisplayNames"]=Series24DisplayNames;

    jsonItem['BusinessReviewStatus'] = reviewersStatus.businessStatus; 
    jsonItem['QRMReviewStatus'] = reviewersStatus.qrmStatus; 
    jsonItem['ComplianceReviewStatus'] = reviewersStatus.complianceStatus;
    jsonItem['BusinessReturnedForRework'] = reviewersStatus.businessReturnedForRework; 
    jsonItem['QRMReturnedForRework'] = reviewersStatus.qrmReturnedForRework; 
    jsonItem['ComplianceReturnedForRework'] = reviewersStatus.complianceReturnedForRework;
   
    if(reviewersStatus.series24ReturnedForRework!=undefined && reviewersStatus.series24ReturnedForRework!=null &&
        reviewersStatus.series24ReturnedForRework == "YES")
        {
            jsonItem['series24ReturnedForRework'] = "NO";
        }

    if (QRMComplianceNotificationNeeded) {
        jsonItem['QRMComplianceNotificationNeeded'] ="True";
    }
    return $.ajax({
        url: apiUrl + "CrossBorderForm/Update",
        type: "POST",
        contentType: "application/json;odata=verbose",
        data: JSON.stringify(jsonItem),
        headers: {
            "accept": "application/json;",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        }
    })    
}

function setReviewProcessStatus(formId, reviewProcessContext, isBusinessReview, additionalReviewNeeded){ 
    var deferred = $.Deferred();
    var reviewersStatus = {
        'formStatus' : reviewProcessContext.isDocumentReviewProcessComplete(),
        'businessStatus' :  reviewProcessContext.isBusinessReviewCompletedView(),
        'qrmStatus' : reviewProcessContext.isQRMReviewCompletedView(),
        'complianceStatus' : reviewProcessContext.isComplianceReviewCompletedView(),
        'businessReturnedForRework' : reviewProcessContext.businessReviewsReturnedForReworkView,
        'qrmReturnedForRework' : reviewProcessContext.qrmReviewsReturnedForReworkView,
        'complianceReturnedForRework' : reviewProcessContext.complianceReviewsReturnedForReworkView
    }
    var formReviewed = isFormReviewed(reviewersStatus.formStatus);
    if (formReviewed) {
        stopAutoSave();
    }
    
    var qrmComplianceReviewNeeded = false;
    if (typeof(additionalReviewNeeded) == "undefined") {
        additionalReviewNeeded = additionalReviewNeededNA;
    }

    if(reviewersStatus.businessStatus == reviewedStatus && isBusinessReview && additionalReviewNeeded == additionalReviewNeededNA)
    {
        qrmComplianceReviewNeeded = true;
    }

    $.when(apiPutReviewerStatus(formId, reviewersStatus, qrmComplianceReviewNeeded)).done(function () {
        if (formReviewed) {
            gblFormStatus = reviewedStatus;
            $.when(apiPut(formId)).done(function() {
                showModalRedirectOrSave(gblFormStatus);
                disableFormActionsAfterDocumentReviewProcessComplete();
                deferred.resolve(true);
            }).fail(
                function (error) {
                    reactivateForm();
                });
        } else {
            deferred.resolve(true);
        }
    });
    return deferred.promise();
}

function isFormReviewed(formStatus) {
    return formStatus == reviewedStatus;
}

function getTotalPossibleReviewByRol(){
    var totalPossibleReviewByRol = 0;
    $.each($("[document-type]"), function() {
    var current = $(this);
    var documentTypeId = current.closest(".row").find("input[type=file]").attr("document-type");
        if (haveDocumentTypeAttachments(documentTypeId)) {
            totalPossibleReviewByRol++;
        }
    }) 
    return totalPossibleReviewByRol; 
}

function closeReviewAssistantModal() {
    $("#review-assistant-modal .close").click();
    cleanReviewCommentsData();
}

function deleteChildReviewAssistantModalSpinner() {
    $(".modal-body-review-assistant").find(".modal-body-spinner").empty();
}

function enableCloseReviewAssistantModal () {
    $("#review-assistant-modal .close").attr("data-dismiss","modal");
    $("#review-assistant-modal").find(".close-modal").addClass("close-button-review-assistant-modal");
}

function disableCloseReviewAssistantModal () {
    $("#review-assistant-modal .close").attr("data-dismiss","");
    $("#review-assistant-modal").find(".close-modal").removeClass("close-button-review-assistant-modal");
}

function cloneAndReplaceReviewStatusIcon(openbox,icon,role, documentTypeId) {
    var parent = openbox.parent();
    openbox.remove();
    if (parent.length == 0) {
        var openBoxToReplace = $(".review-status-row-document-type-" + documentTypeId).find($("[role=" + role +"]"));
        parent = openBoxToReplace.closest("div");
        openBoxToReplace.parent().remove();
    } 
    var newIcon = icon.clone();
    var reviewIcon = new ReviewIcon(newIcon);
    if (reviewIcon.isPendingIcon(newIcon) || reviewIcon.isReturnedForReworkIcon(newIcon)) {
        newIcon.addClass("cursor-pointer");
    }
    newIcon.removeClass("review-assistant-buttons");
    newIcon.removeClass("review-na");
    newIcon.removeClass("review-action");
    newIcon.addClass("review-assistant-icon-status");
    parent.append(newIcon);
}

function createReviewProcessContext() {
    var totalPossibleReview = $("[document-type]").toArray().length * documentReviewProcessReviewersCount;
    var totalPossibleReviewByRol = getTotalPossibleReviewByRol();
    return new ReviewProcessContext(totalPossibleReview, totalPossibleReviewByRol);
}

function validateReview(formId, documentTypeId, isBusinessReview, additionalReviewNeeded) {
    var deferred = $.Deferred();
    deleteChildReviewAssistantModalSpinner();
    reviewProcessContext = createReviewProcessContext();
    $.when(reviewProcessContext.calculateReview(formId)).done(function(reviewProcessContextData){
        $.when(setReviewProcessStatus(formId, reviewProcessContextData, isBusinessReview, additionalReviewNeeded)).done(function() {
            $(".modal-footer-review-assistant").on('click', '.review-assistant-buttons');
            deferred.resolve(true);
        });
    });
    return deferred.promise();
}

function getReviewStatusItem(formId,documentType) {
    var deferred = $.Deferred();
    var jsonItem = {};
    jsonItem['FormID'] = formId; 
    jsonItem['DocumentType'] = documentType;
    $.ajax({
        url: apiUrl+"CrossBorderApprovalReviewStatus/GetList",
        type: "POST",
        data: JSON.stringify(jsonItem),
        headers: {
            "accept": "application/json;odata=verbose",
            "content-Type": "application/json",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function (data) {
            deferred.resolve(data);
        },
        error: function (error) {
            deferred.reject(data);
        }
    });
    return deferred.promise();
}

// function getReviewStatusId(status) {   
//     return $.ajax({
//         url: siteUrl + "/_api/web/lists/getbytitle('"+ reviewStatusList +"')/items?$filter=ReviewStatus eq '" + status + "'",
//         method: "GET",
//         contentType: "application/json;odata=verbose",
//         headers: {
//             "Accept": "application/json;odata=verbose"
//         }
//     });
// }

function saveReviewStatus(reviewIcon, button, formId, reviews,comment,commentType) {
    var deferred = $.Deferred();
    var reviewPerson = currentUser.userId;
    var reviewStatus = button.attr("status"); 
    var reviewRol = button.attr("role"); 
    var documentTypeId = button.attr("document-type-icon-status");
    var reviewDate = new Date().toISOString();    
    var isBusinessReview = reviewIcon.isBusiness();
    //getReviewStatusId(reviewStatus).done(function(statusData){
      //  var reviewStatusId = statusData.d.results[0].Id;
      var reviewStatusId=reviewStatus;
      var documentType="";
      if(documentTypeList != undefined && documentTypeList.length > 0)
      {
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
        documentType = docTypeObj[0].DocumentTypeTitle;
      }   
        $.when(getReviewStatusItem(formId,documentType)).then(function(statusItemData){
            var reviewDate = new Date().toISOString();
            if (statusItemData.length > 0){
                var reviewItemId = statusItemData[0].ID;
                var additionalReviewNeeded = statusItemData[0].AdditionalReviewNeeded;

                if(reviewIcon.isBusiness() && reviewIcon.isReviewedIcon() && isAdditionalQRMComplianceReviewNeededEnabled()){
                    additionalReviewNeeded = $("[name=AdditionalQRMComplianceReviewNeeded]").prop("checked") ? additionalReviewNeededYes : additionalReviewNeededNo;                                 
                } else if(reviewIcon.isReturnedForReworkIcon()){
                    additionalReviewNeeded = additionalReviewNeededNA;
                }

                var reviewerType = '';
                if(reviewIcon.isBusiness())
                {
                    reviewerType = "Business";
                }
                else if(reviewIcon.isQRM())
                {
                    reviewerType = "Prescreener";
                }
                else if(reviewIcon.isCompliance())
                {
                    reviewerType = "Compliance";
                }
                var reviewStatusRow = $(".review-status-row-document-type-" + documentTypeId);
                reviewStatusRow.attr("review-item-id",reviewItemId); 
                reviewStatusRow.attr("additional-review-needed",additionalReviewNeeded);
                reviews.push(createReviewObject(reviewStatusId, reviewRol, reviewPerson, reviewDate));

                $.when(apiPutReviewAction(reviews,documentType,reviewItemId,additionalReviewNeeded,statusItemData[0],reviewerType,comment,commentType)).then(function(){
                    $.when(validateReview(formId, documentTypeId, isBusinessReview, additionalReviewNeeded)).done(function() {
                        deferred.resolve(true);
                    });
                });
            } else {
                $.when(apiPostReviewAction(reviewStatusId,reviewPerson, reviewRol, reviewDate,documentType,comment,commentType)).then(function(data){
                    var reviewStatusRow = $(".review-status-row-document-type-" + documentTypeId);
                    reviewStatusRow.attr("review-item-id",data.ID);
                    reviewStatusRow.attr("additional-review-needed",data.AdditionalReviewNeeded);
                    $.when(validateReview(formId, documentTypeId, isBusinessReview, additionalReviewNeeded)).done(function() {
                        deferred.resolve(true);
                    });
                });
            }
        });
    //});
    return deferred.promise();
}
function apiPostReviewAction(reviewStatus,reviewPerson, reviewRol, reviewDate, documentType,comment,commentType) {
        var deferred = $.Deferred();
        var jsonItem = {};
        jsonItem[reviewRol+'ReviewStatus'] = reviewStatus;
        jsonItem[reviewRol+'Reviewer'] = reviewPerson;
        jsonItem[reviewRol+'ReviewDate'] = reviewDate;
        jsonItem['FormID'] = gblFormId;
        jsonItem['DocumentType'] = documentType;
        jsonItem['Createdby'] = currentUser.userId;
        jsonItem['CreatedbyEmail'] = currentUser.email;
        jsonItem['ProjectName'] = xbProjectName;
        jsonItem['ReviewerType'] = '';
        jsonItem['CommentType'] = commentType;
        jsonItem['Comment'] = comment;
        jsonItem['ModifiedBy'] = currentUser.email;
        
        $.ajax({
            url: apiUrl+"CrossBorderApprovalReviewStatus/Add",
            type: "POST",
            data: JSON.stringify(jsonItem),
            headers: {
                "accept": "application/json",
                "content-Type": "application/json",
                "Authorization" : "Bearer "+localStorage.getItem("idToken"),
                "FormType" : localStorage.getItem("FormType"),
                "UserRoles": getUserRoles()
            },
            success: function(data){
                deferred.resolve(data);
                if(isXBAdmin)
                {
                    $("#AuditFormButton").prop('disabled',false);
                }
            },
            error: function(){
                showError(data);
            }
        })
    return deferred.promise();
}
function apiPutReviewActionReset(reviewList, documentType, reviewItemId, additionalReviewNeeded, statusItemData)
{  var deferred = $.Deferred();
    var jsonItem = {};
    var BusinessReview='Business';
    var QRMReview=' QRM';
    var ComplianceReview='Compliance';

    jsonItem['ID'] = reviewItemId
    jsonItem['CreatedBy'] = currentUser.userId;
    jsonItem['FormID'] = gblFormId;
    jsonItem['DocumentType'] = documentType;
    jsonItem['ReviewerType'] = '';
    jsonItem['CommentType'] = '';
    jsonItem['Comment'] = '';
    jsonItem['ProjectName'] = xbProjectName;

    for (var i=0; i < reviewList.length; i++) {
        var reviewElement = reviewList[i];
        var reviewRol = reviewElement.reviewRol;
        jsonItem[reviewRol+'ReviewStatus'] = reviewElement.reviewStatus;
        jsonItem[reviewRol+'Reviewer'] = null;
        jsonItem[reviewRol+'ReviewDate'] = null;
    }
    if(typeof(additionalReviewNeeded) != "undefined"){
        jsonItem['AdditionalReviewNeeded'] = additionalReviewNeeded;
    }
    jsonItem['CreatedbyEmail'] = currentUser.email;
    $.ajax({
        url: apiUrl+"CrossBorderApprovalReviewStatus/Update",
        type: "POST",
        async: false,
        contentType: "application/json",
        data: JSON.stringify(jsonItem),
        headers: {
            "accept": "application/json;",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function(data){
            deferred.resolve(data);            
        },
        error: function(e){
            showError(e);
        }
    })
    return deferred.promise();
    
}

function apiPutReviewAction(reviewList, documentType, reviewItemId, additionalReviewNeeded, statusItemData,reviwerType,comment,commentType) {   
   
    var deferred = $.Deferred();
    var jsonItem = {};
    var BusinessReview='Business';
    var QRMReview=' QRM';
    var ComplianceReview='Compliance';

    jsonItem['ID'] = statusItemData.ID;
    jsonItem['CrossBorderReviewProcessWorkflow'] = statusItemData.CrossBorderReviewProcessWorkflow;
    jsonItem['CreatedBy'] = statusItemData.CreatedBy;
    jsonItem['AutomaticReviewDate'] = statusItemData.AutomaticReviewDate;
    jsonItem['FormID'] = statusItemData.FormID;
    jsonItem['DocumentType'] = documentType;
    jsonItem['ReviewerType'] = reviwerType;
    jsonItem['CommentType'] = commentType;
    jsonItem['Comment'] = comment;

    var businessExist = reviewList.filter(obj=>obj.reviewRol==BusinessReview.trim());
    if(businessExist.length<1)
    {
        jsonItem['BusinessReviewStatus'] = statusItemData.BusinessReviewStatus;
        jsonItem['BusinessReviewer'] = statusItemData.BusinessReviewer;
        jsonItem['BusinessReviewDate'] = statusItemData.BusinessReviewDate;
    }
    var QRMExist = reviewList.filter(obj=>obj.reviewRol==QRMReview.trim());
    if(QRMExist.length<1)
    {
        jsonItem['QRMReviewStatus'] = statusItemData.QRMReviewStatus;
        jsonItem['QRMReviewer'] = statusItemData.QRMReviewer;
        jsonItem['QRMReviewDate'] = statusItemData.QRMReviewDate;
    }
    var ComplianceExist = reviewList.filter(obj=>obj.reviewRol==ComplianceReview.trim());
    if(ComplianceExist.length<1)
    {
        jsonItem['ComplianceReviewStatus'] = statusItemData.ComplianceReviewStatus;
        jsonItem['ComplianceReviewer'] = statusItemData.ComplianceReviewer;
        jsonItem['ComplianceReviewDate'] = statusItemData.ComplianceReviewDate;
    }
    var currentReviewitem;
    reviewList=reviewList.filter(x=>x.reviewDate!=null);
    for (var i=0; i < reviewList.length; i++) {
        var reviewElement = reviewList[i];
        var reviewRol = reviewElement.reviewRol;
        currentReviewitem=reviewElement.reviewStatus;;
        jsonItem[reviewRol+'ReviewStatus'] = reviewElement.reviewStatus;
        jsonItem[reviewRol+'Reviewer'] = reviewElement.reviewPersonId;
        jsonItem[reviewRol+'ReviewDate'] = reviewElement.reviewDate;
    }
    if(typeof(additionalReviewNeeded) != "undefined"){
        jsonItem['AdditionalReviewNeeded'] = additionalReviewNeeded;
    }
    if(currentReviewitem=="Reviewed")
    {
        if(jsonItem['ComplianceReviewStatus']!=undefined && jsonItem['ComplianceReviewStatus']=="Returned For Rework")
        {
            jsonItem['ComplianceReviewStatus']=null;
        }
        if(jsonItem['QRMReviewStatus']!=undefined && jsonItem['QRMReviewStatus']=="Returned For Rework")
        {
            jsonItem['QRMReviewStatus']=null;
        }
        if(jsonItem['BusinessReviewStatus']!=undefined && jsonItem['BusinessReviewStatus']=="Returned For Rework")
        {
            jsonItem['BusinessReviewStatus']=null;
        }
    }
    jsonItem['CreatedbyEmail'] = currentUser.email;
    jsonItem['ProjectName'] = xbProjectName;
    jsonItem['ModifiedBy'] = currentUser.email;
    $.ajax({
        url: apiUrl+"CrossBorderApprovalReviewStatus/Update",
        type: "POST",
        async: false,
        contentType: "application/json;odata=verbose",
        data: JSON.stringify(jsonItem),
        headers: {
            "accept": "application/json;odata=verbose",
            "content-Type": "application/json",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function(data){
            deferred.resolve(data);
            if(isXBAdmin)
            {
                $("#AuditFormButton").prop('disabled',false);
            }
        },
        error: function(e){
            showError(e);
        }
    })
    return deferred.promise();
}

function apiPostNAReview(jsonItem) {
    var deferred = $.Deferred();
    jsonItem['CreatedbyEmail'] = currentUser.email;
    jsonItem['ProjectName'] = xbProjectName;
    jsonItem['ReviewerType'] = '';
    jsonItem['CommentType'] = '';
    jsonItem['Comment'] = '';
    jsonItem['ModifiedBy'] = currentUser.email;
    $.ajax({
        url: apiUrl+"CrossBorderApprovalReviewStatus/Add",
        type: "POST",
        data: JSON.stringify(jsonItem),
        headers: {
            "accept": "application/json",
            "content-Type": "application/json",
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        success: function(data){
            deferred.resolve(data);
            if(isXBAdmin)
            {
                $("#AuditFormButton").prop('disabled',false);
            }
        },
        error: function(data){
            showError(data);
        }
    })
return deferred.promise();
}

//event to show review asssitant modal

function removeReviewAssistantModalEventWhenAttach() {
    $(".attachments-rows").off('click', '.review-assistant-open-box-click, .pending-review-icon, .return-review-icon'); //cant click on open-boxes
    $("#review-assistant-modal").find(".review-action").removeClass("review-assistant-buttons-click");
    disableCloseReviewAssistantModal(); //cant close the modal
}

function addReviewAssistantModalEventWhenAttach() {
    if (securityContext.canReview()) {
        enableClickOpenBoxesForRoles(securityContext);
        var uploadInProgress = $(".redline-attachment-section-modal").find(".uploading");
        if (uploadInProgress.length == 0)  {
            $(".attachments-rows").on('click', '.review-assistant-open-box-click, .pending-review-icon, .return-review-icon', openReviewAssistant);
            $("#review-assistant-modal").find(".review-action").addClass("review-assistant-buttons-click");
            enableCloseReviewAssistantModal();
        }
    }
}

function removeDocumentTemplateEventWhenAttach() {
    disableClickDocumentTemplateModal();
}

function addDocumentTemplateEventWhenAttach() {
    var uploadInProgress = $("#recommended-document-type-templates").find(".uploading");
    if (uploadInProgress.length == 0)  {
        enableClickDocumentTemplateModal();
    }
}

function enableClickDocumentTemplateModal () {
    $("#templates-modal .close").attr("data-dismiss","modal");
    $(".modal-footer-document-template").find(".close-modal").attr("data-dismiss","modal");
    $(".modal-footer-document-template").find(".close-modal").addClass("close-button-template-modal");
}

function disableClickDocumentTemplateModal () {
    $("#templates-modal .close").attr("data-dismiss","");
    $(".modal-footer-document-template").find(".close-modal").attr("data-dismiss","");
    $(".modal-footer-document-template").find(".close-modal").removeClass("close-button-template-modal");
}

function setRoleReviewAction (role) {
    $('.review-action').find("button").attr("role",role);
}

function setDocumentTypeReviewAction (documentTypeId) {
    $('.review-action').find("button").attr("document-type-icon-status",documentTypeId);
}

function setDocumentTypeAttachmentSection (documentTypeId) {
    $(".attachment-section-document-type").attr("id", "attachment-section-document-type-" + documentTypeId);
}

function disableClickAttachRedLineDocuments () {
    $(".enable-open-selector-file").prop('disabled', true);
}

function enableClickAttachRedLineDocuments () {
    $(".enable-open-selector-file").prop('disabled', false);
}

function openReviewAssistant(e) {
    $(".attachments-rows").off('click', '.review-assistant-open-box-click, .pending-review-icon, .return-review-icon');
    e.preventDefault();
    addClassRequiredInput("DFCRiskRating");

    if ((reviewProcessContext.isTheLastReview() && isValidAction('lastReview')) || !reviewProcessContext.isTheLastReview()) {
        var openbox = $(this);
        var role = openbox.find("button").attr("role");
        var documentTypeId = openbox.closest(".row").find('input[type=file]').attr("document-type");
        var documentType = openbox.closest(".row").find('button').attr("document-title");
        setRoleReviewAction(role);
        setDocumentTypeReviewAction (documentTypeId);
        setDocumentTypeRedlineSection (documentTypeId);
        setDocumentTypeAttachmentSection (documentTypeId);
        addRedLineDocumentsReviewAssistantModal(documentTypeId);
        $.when(addAttachmentsToReviewAssistantModal(documentType)).done(function() {
            registerIconReviewAssistantEvent(openbox, gblFormId);
            enableAdditionalQRMComplianceReviewNeededOption(openbox,documentTypeId);
            clickAttachmentModalButton();
            registerCloseReviewAssistantModal(documentTypeId);
            removeClassRequiredInput("DFCRiskRating");
            $(".attachments-rows").on('click', '.review-assistant-open-box-click, .pending-review-icon, .return-review-icon', openReviewAssistant);
        })
    } else {
        removeClassRequiredInput("DFCRiskRating");
        $(".attachments-rows").on('click', '.review-assistant-open-box-click, .pending-review-icon, .return-review-icon', openReviewAssistant);
    }
}

$(".attachments-rows").on('click', '.review-assistant-open-box-click, .pending-review-icon, .return-review-icon', openReviewAssistant)

function showData(documntType,files) {
	//var folderUrl = createFolderUrl(attachEnviroment, gblFormId);
	var sectionToAttach = $(".modal-attachment-ul")
    sectionToAttach.empty();
    var dateSection = $(".modal-date-attachment-section").find("ul");
    dateSection.empty();
  // Start with an immediately resolved promise.
  let promise = new Promise((resolve, reject) => resolve());
  // Add each element to the chain.
  files.forEach(file => { 
   //var current = files[file];
    promise = promise.then(() => {
		 if (file.DocumentType == documntType) {
			 var name = file.Name;
             file.Modified=ConvertUTCToIST(file.Modified);  
          var date = getCSTDateTimeString(file.Modified,true);
     // return getUserInfo(file.AuthorId)
        //.then(data => {
          var uploadedBy = file.ModifiedBy;
                var uploadedByEmail = file.ModifiedBy;
                var newAttachment = generateLiAttachment(name,'CrossBorderAttachments',gblFormId);
                var divAttachment = generateLiAttachmentReviewAssistantModal(newAttachment, date, uploadedBy, uploadedByEmail);
                sectionToAttach.append(divAttachment);
        //})
		 }
    })
  })
}

function addAttachmentsToReviewAssistantModal(documentType) {
    // var deferred = $.Deferred();
    // var sectionToAttach = $(".modal-attachment-ul")
    // sectionToAttach.empty();
    // var dateSection = $(".modal-date-attachment-section").find("ul");
    // dateSection.empty();
     //var folderUrl = createFolderUrl(attachEnviroment, gblFormId);
    $.when(getFiles(gblFormId)).done(function(dataFile){
        var files = dataFile;
				return showData(documentType,files);
        // for (file in files) {
            // var current = files[file];
            // if (current.ListItemAllFields.DocumentTypeId == documentTypeId) {
                // var name = current.Name;
                // var date = getCSTDateTimeString(current.TimeLastModified);
                // var uploadedBy = current.ModifiedBy.Title;
                // var uploadedByEmail = current.ModifiedBy.Email;
                // var newAttachment = generateLiAttachment(name,folderUrl,gblFormId);
                // var divAttachment = generateLiAttachmentReviewAssistantModal(newAttachment, date, uploadedBy, uploadedByEmail);
                // sectionToAttach.append(divAttachment);
            // }
        // }
        deferred.resolve();
    })
   // return deferred.promise();
}

function generateLiAttachmentReviewAssistantModal (newAttachment, date , uploadedBy, uploadedByEmail) {    
    return '<div class="col-xs-12"><div class="col-xs-4">' + newAttachment + '</div><div class="col-xs-4"><li class="padding-left-5"><a href="mailto:'+ uploadedByEmail +'"><span class="margin-left-15">'+ uploadedBy +'</span></a></li></div><div class="col-xs-4"><li><span class="margin-left-30">' + date + '</span></li></div></div>';
}

function generateDateToAttachmentReviewAssistantModal(date) {
    return '<li><span>' + date + '</span></li>';
}

function clickAttachmentModalButton() {
    $('body').css('overflow','hidden');
    $("#review-assistant-modal").modal('show');
    //moveToFrontModal("#review-assistant-modal");
    moveToFrontReviewAssistant();
}

//event to show document templates modal

$(".attachments-rows").on('click', '.open-templates', function (e) {
    var template = $(this);
   // template.removeClass("open-templates");
    e.preventDefault();
    var documentTypeId = template.closest(".row").find('input[type=file]').attr("document-type"); 
    $(".attachRecommendedSection").attr("attachment-section-document-type", documentTypeId);
    $.when(populateDocumentTypeTemplates(documentTypeId,template, getTemplates(documentTypeId), "<span class='modal-span'>There are no templates available for download.</span>", "Select a Template:", maxTemplates,""),
        populateDocumentTypeTemplates(documentTypeId,template, getRecommendedTemplates(documentTypeId), undefined, undefined, undefined,'fromRecommended')).done(function(htmlStaticData, htmlRecommendedData) {
            $('#document-type-templates').html(htmlStaticData);
            $('#recommended-document-type-templates').find(".file-list").html("");
            $('#recommended-document-type-templates').find(".file-list").append(htmlRecommendedData);
            clickTemplatesModalButton();
            moveToFrontReviewAssistant(template);
    })
})

//fill the document type templates

function populateDocumentTypeTemplates(documentTypeId,template, functionGetTemplates, noTemplatesMessage, titleMessage, maxTemplatesToDisplay,fromRecommendedTemplate) {
    var deferred = $.Deferred();
    $.when(functionGetTemplates).done(function(data){
        var docuemntType  =documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId && obj.isActive != "No");
        var templates = data.filter(function (obj) {
            return obj.DocumentType == docuemntType[0].DocumentTypeTitle;
        });
        
        var availableTemplates = templates.length;
        if(availableTemplates > 0){
            if (typeof(maxTemplatesToDisplay) != "undefined" && availableTemplates > maxTemplatesToDisplay){
                templates.sort(function(a,b){
                   return new Date(b.TimeLastModified) - new Date (a.TimeLastModified);
                });
                templates = templates.slice(0,maxTemplatesToDisplay);
            }
            templates.sort(function(a,b){
                var x = a.Title.toLowerCase();
                var y = b.Title.toLowerCase();
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
            var htmlData = "";
            if (typeof(titleMessage) != "undefined") {
                htmlData = "<span class='modal-span'>" + titleMessage + "</span>";
            }
            for (var key in templates) {
                var templateTitle = templates[key].Title;
                var templateName = templates[key].Name;
                //var templateUrl = encodeURIComponent(templates[key].ServerRelativeUrl.replace(siteRelativeUrl, ""));
                var fileInfo = getRecommendedFileName(templateTitle);
                var tooltip = "";
                var EnvironmentPath=crossBorderTemplates;
                if(fromRecommendedTemplate=="fromRecommended")
                EnvironmentPath=attachRecommendedSectionEnviroment;

                var newTemplate = '<div class="display-inline-flex" {0} style="margin: 0.5em" data-filename="' + templateName.toLowerCase() + '"><a  href="javascript:downloadAttachments(`'
                +gblFormId+'`,`'+templateName+'`,`'+EnvironmentPath+'`)"  class="btn btn-download-template search-name">' + fileInfo.fileName + '</a></div>';
                if (fileInfo.showTooltip){
                    tooltip = 'title="'+ templateTitle +'"';        
                }
                htmlData += newTemplate.replace("{0}", tooltip);
            }
        }
        else {
            if (typeof(noTemplatesMessage) != "undefined") {
                htmlData = noTemplatesMessage;
            }
        }
        deferred.resolve(htmlData);
   });
    return deferred.promise();
}

function closeTemplateModal(template) {
    $("#templates-modal").off('click', '.close-button-template-modal');
    $("#templates-modal").on('click', '.close-button-template-modal', function (e) {
        template.addClass("open-templates");
    })
}

function closeAdditionalDocumentTypesModal(button) {
    $("#additionalDocumentTypes-modal").on('click', '.close-button-addtional-documents-type-modal', function (e) {
      $('#additionalDocumentTypes-modal').hide();
      $('body').css('overflow','auto');  
       button.addClass("addAdditionalDocumentTypes");
    })
    moveToFrontModal($("#review-assistant-modal"));
    moveToFrontModal($("#additionalDocumentTypes-modal"));
}

function clickTemplatesModalButton() {
   // $("#templates-modal-button").click();
   $('body').css('overflow','hidden');
    $('#templates-modal').modal('show');
}

function clickAdditionalDocumentTypesModalButton() {
    //$("#-modal-button").click();
    $('body').css('overflow','hidden');
    
    $('#additionalDocumentTypes-modal').modal('show');
}

//add and remove attachments logic

function removeCrossToDeleteAttachments(crossIcon, crossIconRedLines) {
    crossIcon.addClass("visibility-hidden");
    crossIconRedLines.addClass("visibility-hidden");
}

function showCrossToDeleteAttachments(crossIcon, crossIconRedLines) {
    crossIcon.removeClass("visibility-hidden");
    crossIconRedLines.removeClass("visibility-hidden");
}

function findReviewsToDelete(newReview, documentTypeId){
    var reviewIcon = new ReviewIcon(newReview);
    var reviewIconHelper = new ReviewIconHelper();
    var reviewsToDelete = [];
    if (securityContext.canReview()) {
        var additionalReviewNeeded = $("[name=AdditionalQRMComplianceReviewNeeded]").prop("checked");
        var reviews = $(".open-boxes-document-type-" + documentTypeId);
        if (reviewIcon.isReviewedIcon()) {
            reviewsToDelete = reviewIconHelper.findReturnedForReworkReviews(reviews);        
            if(reviewIcon.isBusiness() && additionalReviewNeeded){
                reviewsToDelete = reviewIconHelper.findQRMAndComplianceReviews(reviews);
            }
        } else {
            if (reviewIcon.isReturnedForReworkIcon()) {
                reviewsToDelete = reviewIconHelper.findReviewedNaPendingReviews(reviews);
            }
        }
    }
    return reviewsToDelete;
}

function generateReviewObjectsToDelete(reviewsToDelete) {
    var reviews = [];
    $.each(reviewsToDelete, function(){
        var review = $(this);
        var role = review.find("button").attr("role");
        var reviewToAdd = createReviewObject (null, role, -1, null);
        reviews.push(reviewToAdd);
    });
    return reviews;
}

function replaceReviewIcon(reviews){
    $.each(reviews, function(){
        var review = $(this);
        var role = review.find("button").attr("role");
        var openBox = generateOpenBox(role);
        review.parent().html(openBox);
        enableClickOpenBoxesForRoles(securityContext);
    })
}

function createReviewObject (reviewStatus, reviewRol, reviewPersonId, reviewDate){
    var newReview = {};
    newReview['reviewStatus'] = reviewStatus;
    newReview['reviewRol'] = reviewRol;
    newReview['reviewPersonId'] = reviewPersonId;
    newReview['reviewDate'] = reviewDate;
    return newReview;
}

function generateOpenBox(role) {
    return '<span class="review-assistant-open-box no-cursor-pointer fa fa-square-o ' + role.toLowerCase() + 'ReviewStatus check-status">' +
    '<button type="button" hidden role="' + role + '"></button></span>';
}

function reviewHaveReturnForReworkIcons (reviewSection) {
    return reviewSection.find(".fa-times-circle").length == 0;
}

function reviewHavePendingOrNAorReviewedIcon (reviewSection) {
    return reviewSection.find(".fa-check-circle, .glyphicon-log-out, .fa-clock-o").length > 1;
}

function verifyIfCanDeleteAttachments(icon, documentTypeId) {
    var crossIcon = $("#attachment-section-document-type-" + documentTypeId).find(".delete-uploaded-file");
    var crossIconRedLines = $(".redline-attachment-section-" + documentTypeId).find(".delete-uploaded-file");
    var reviewIcon = new ReviewIcon(icon);
    if (reviewIcon.isPendingIcon() || reviewIcon.isNaIcon() || reviewIcon.isReviewedIcon()) {
        removeCrossToDeleteAttachments(crossIcon, crossIconRedLines);
    }
    else {
        showCrossToDeleteAttachments(crossIcon, crossIconRedLines);
    }
}

function verifyIfCanDeleteAttachmentWhenAdd(ulToAdd) {
    var row = ulToAdd.closest(".row");
    var documentTypeId = row.find("input[type=file]").attr("document-type");
    var reviewSection = $(".review-status-row-document-type-" + documentTypeId);
    if ((!securityContext.canDeleteAttachments()) && (reviewHavePendingOrNAorReviewedIcon(reviewSection) || reviewHaveReturnForReworkIcons(reviewSection))){
        ulToAdd.find(".delete-uploaded-file").addClass("visibility-hidden");
    }
}

function registerIconReviewAssistantEvent(openbox, formId) {
    $(".modal-footer-review-assistant").off('click', '.review-assistant-buttons-click');
    $(".modal-footer-review-assistant").on('click', '.review-assistant-buttons-click', function(e){
        e.preventDefault();
        var icon = $(this);
        var reviewIcon = new ReviewIcon(icon);
        var additionalReviewNeeded = $("[name=AdditionalQRMComplianceReviewNeeded]").prop("checked");
        if(additionalReviewNeeded && !reviewIcon.isReviewedIcon()) {            
            showModalMessage(additinalQRMComplianceReviewNeeded);
        } else {
            $("#review-assistant-modal").find(".review-action").removeClass("review-assistant-buttons-click");
            disableCloseReviewAssistantModal();
            disableClickAttachRedLineDocuments();            
            var reviewAssistant = $(".modal-body-review-assistant"); 
            var comment = reviewAssistant.find("textarea").val();
            if (reviewIcon.isReturnedForReworkIcon() && (!comment.trim())) {
                showModalMessage(missingCommentsReviewForRework);
                enableCloseReviewAssistantModal();
                enableClickAttachRedLineDocuments();
                $("#review-assistant-modal").find(".review-action").addClass("review-assistant-buttons-click");
            }
            else {
                var button = icon.find("button");
                var role = openbox.find("button").attr("role");
                var documentTypeId = button.attr("document-type-icon-status");
                var reviewsToDelete = findReviewsToDelete(icon, documentTypeId);
                var reviews = generateReviewObjectsToDelete(reviewsToDelete); 
                
                reviewAssistant.find(".modal-body-spinner").append('<span><i class="fa fa-spinner fa-spin icon-spinner-review-assistant"></i></span>');
                var commentType = (reviewAssistant.find("input:checkbox").prop("checked") ? "Internal" : "External");
                $.when(saveReviewComment(button, reviewAssistant, documentTypeId, formId, comment,commentType)).done(function () {
                    $.when(saveReviewStatus(reviewIcon, button, formId,reviews,comment,commentType)).done(function() {
                        enableCloseReviewAssistantModal();
                        closeReviewAssistantModal(documentTypeId);
                        enableClickAttachRedLineDocuments();
                        cloneAndReplaceReviewStatusIcon(openbox,icon,role, documentTypeId);
                        addRedLineDocumentsToAttachmentSection(documentTypeId);
                        verifyIfCanDeleteAttachments(icon, documentTypeId);
                        $("#review-assistant-modal").find(".review-action").addClass("review-assistant-buttons-click");
                        replaceReviewIcon(reviewsToDelete);
                    });
                });                
            }
        }
    });
}

function saveReviewComment(button, reviewAssistant, documentTypeId, formId, comment,commentType) {
    var deferred = $.Deferred();
    if(comment.trim()){
        var reviewStatus = button.attr("status");
        //getReviewStatusId(reviewStatus).done(function(statusData){
            var reviewStatusId = reviewStatus;
            var documentType="";
                if(documentTypeList != undefined && documentTypeList.length > 0)
    {
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
            documentType = docTypeObj[0].DocumentTypeTitle;
    }               
            $.when(apiPostReviewComments(formId, documentType, commentType, comment, reviewStatusId)).then(function(dataComment){
                generateReviewCommentWhenCreated(comment, commentType, dataComment, documentTypeId);
                deferred.resolve(true);
            });
       // });
    }
    else {
        deferred.resolve(true);
    }
    return deferred.promise();
}

function hideOpenBoxes() {
    $.each($(".review-assistant-open-box-click"), function() {
        $(this).addClass("hidden");
    })
}

function deleteDocumentTypeButtonColorClass(button) {
    button.removeClass("document-type-button-color-green");
}

function deleteReviewIcon(documentTypeId) {
    var openBoxesDiv = $(".open-boxes-document-type-" + documentTypeId);
    openBoxesDiv.find(".review-assistant-icon-status").remove();
}

function deleteOpenBoxesByDocumentTemplate (documentTypeId) {
    var reviewItemId;
    var openBoxesDiv = $(".open-boxes-document-type-" + documentTypeId);
    openBoxesDiv.remove();
    var documentType="";
      if(documentTypeList != undefined && documentTypeList.length > 0)
      {
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
        documentType = docTypeObj[0].DocumentTypeTitle;
      }   
        $.when(getReviewStatusItem(gblFormId,documentType)).then(function(statusItemData){

            if(statusItemData.length>=1) 
            {
             deleteReviewStatusItem( statusItemData[0].ID);
            }
        });
}

function deleteReviewStatusItem(itemId) { 
     $.ajax({
                url:apiUrl+"CrossBorderApprovalReviewStatus/Delete?partitionKeyValue="+itemId,
                type: "GET",
                contentType: "application/json",
                headers: {
                    "Authorization" : getTokenValue(),
                    "FormType" : getFormType(),
                    "UserRoles": getUserRoles()
                },
                success: function(data) {        
                },
                error: function(data) {
                    showError(data);
                }
            });    
   
    // return $.ajax({
    //     url: apiUrl+"",
    //     method: "POST",
    //     contentType: "application/json;odata=verbose",
    //     headers: {
    //         "X-RequestDigest": $("#__REQUESTDIGEST").val(),
    //         "X-Http-Method": "DELETE",
    //         "If-Match": "*"
    //     }
    // })
}

function addOpenBoxesByDocumentTemplate (documentTypeId) {
    var openBoxesDiv = $(".review-status-row-document-type-" + documentTypeId);
    if (openBoxesDiv.find(".review-assistant-open-box").length == 0 && openBoxesDiv.find(".review-assistant-icon-status").length == 0) {
        openBoxesDiv.append(generateReviewStatusHtml(documentTypeId));
        enableClickOpenBoxesForRoles(securityContext);
    }
}

function generateReviewStatusHtml(documentTypeId) {
    var reviewStatusHtml = '<div class="open-boxes-document-type-' + documentTypeId + '">' + 
        '<div class="col-xs-4 align-center"><span class="review-assistant-open-box no-cursor-pointer fa fa-square-o businessReviewStatus check-status"><button type="button" hidden role="Business"></button></span></div>' + 
        '<div class="col-xs-4 align-center"><span class="review-assistant-open-box no-cursor-pointer fa fa-square-o qrmReviewStatus check-status"><button type="button" hidden role="QRM"></button></span></div>' + 
        '<div class="col-xs-4 align-center"><span class="review-assistant-open-box no-cursor-pointer fa fa-square-o complianceReviewStatus check-status"><button type="button" hidden role="Compliance"></button></span></div>' + 
        '</div>';
    return reviewStatusHtml;
}

function addNABoxesByDocumentType(documentTypeId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies) {
    var openBoxesDiv = $(".review-status-row-document-type-" + documentTypeId);
    var reviewStatus = [];
    if (businessReviewApplies == 'false') {
        reviewStatus.push({
            role: "Business",
            columnReviewStatus: openBoxesDiv.find(".businessReviewStatus").parent(),
            status: "N/A"
        });
    }
    if (qrmReviewApplies == 'false') {
        reviewStatus.push({
            role: "QRM",
            columnReviewStatus: openBoxesDiv.find(".qrmReviewStatus").parent(),
            status: "N/A"
        });
    }
    if (complianceReviewApplies == 'false') {
        reviewStatus.push({
            role: "Compliance",
            columnReviewStatus: openBoxesDiv.find(".complianceReviewStatus").parent(),
            status: "N/A"
        });
    }

    if(reviewStatus.length > 0) {
        addIconToSection(reviewStatus, documentTypeId);
    }
}


function enableDocumentTypeForReview(documentTypeId, folderUrl) {
    //if (isInSection(folderUrl, attachEnviroment)) {
        var parent = $("[id=document-type-" + documentTypeId +"]").parent();
        var button = parent.find("button");
        var businessReviewApplies = button.attr("business-review-applies");
        var qrmReviewApplies = button.attr("qrm-review-applies");
        var complianceReviewApplies = button.attr("compliance-review-applies");
        changeDocumentTypeButtonColor(button);
        if (isFirstAttachment(documentTypeId)) {
            reviewProcessContext = createReviewProcessContext();
            $.when(reviewProcessContext.calculateReview(gblFormId)).done(function(reviewProcessContextData){
                setReviewProcessStatus(gblFormId, reviewProcessContextData);
                addOpenBoxesByDocumentTemplate(documentTypeId);
                if (businessReviewApplies == 'false' || qrmReviewApplies == 'false' || complianceReviewApplies == 'false') {
                   var reviewDate = new Date().toISOString();
            
                    $.when(generateNAReviews(documentTypeId,businessReviewApplies,qrmReviewApplies,complianceReviewApplies)).then(function(data){
                       addNABoxesByDocumentType(documentTypeId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies);
                    });
                }
            });
        }
    //}
}

function changeDocumentTypeButtonColor(button) {
    button.addClass("document-type-button-color-green");
    button.blur();
}

function moveToBackModal(modal) {
    modal.addClass("z-index-0");
}

function moveToFrontModal(modal) {
    modal.removeClass("z-index-0");
}

$(".attachments-rows").on('click', '.addAdditionalDocumentTypes', function (e) {
    
    var additionalDocumentTypeButton = $(this);
    additionalDocumentTypeButton.removeClass("addAdditionalDocumentTypes");
    e.preventDefault();
    getAdditionalDocumentTypes(additionalDocumentTypeButton);    
})

function getAdditionalDocumentTypes(button){
    var required = 1;
    var notRequired = 0;
    var schedule3DocumentRequired = gblSchedule3DocumentRequired;
    $.when(getDocumentTypes(required), getDocumentTypes(notRequired)).done(function(documentTypes, additionalDocumentTypes){
        var additionalDocTypes = additionalDocumentTypes;
        additionalDocTypes=additionalDocTypes.filter(obj=>obj.Required=='FALSE')
        var schedule3Document = documentTypes.filter(x => x.DocumentTypeTitle === 'Schedule 3: US Potential Counterparties')[0];
        //handling schedule3 document for both old forms and new forms
        if(!schedule3DocumentRequired)
            additionalDocTypes.push(schedule3Document);
        var htmlData;
        var documentTypesToRemove = [];
        $('.document-type-button').closest(".row").find('input[type=file]').each(function(){
            documentTypesToRemove.push($(this).attr('document-type-title'));
        });
        additionalDocTypes = cleanAdditionalDocumentTypes(additionalDocTypes,documentTypesToRemove);
        
        //remove inactive document types
        //additionalDocTypes = additionalDocTypes.filter(x=>x.isActive!="No");
        
        if (additionalDocTypes.length > 0){
           htmlData = "<span class='modal-span'>Select a Document Type:</span>";
           for (var key in additionalDocTypes) {
                var documentTypeTitle = additionalDocTypes[key].DocumentTypeTitle;
                var documentTypeId = additionalDocTypes[key].DocumentTypeId;
                var internalId = additionalDocTypes[key].DocumentTypeId;
                var dependentDocumentTypes = additionalDocTypes[key].DependentDocumentTypes;
                var guidance = additionalDocTypes[key].Guidance;
                var _businessReviewApplies = additionalDocTypes[key].BusinessReviewApplies;
                var _qrmReviewApplies = additionalDocTypes[key].QRMReviewApplies;
                var _complianceReviewApplies = additionalDocTypes[key].ComplianceReviewApplies;

                var businessReviewApplies=false,qrmReviewApplies=false,complianceReviewApplies=false;
                if(_businessReviewApplies=="TRUE")
                    businessReviewApplies=true;
                if(_qrmReviewApplies=="TRUE")
                    qrmReviewApplies=true;
                if(_complianceReviewApplies=="TRUE")
                    complianceReviewApplies=true;

                htmlData += "<div class='display-inline-flex' style='margin: 0.5em'><button type='button' guidance='" + guidance + "' class='btn btn-download-template btn-add-document-type' document-type-id='" + 
                            documentTypeId + "' document-internal-id='" + internalId + "' document-type-title='" + documentTypeTitle + "' dependent-document-types='"+ dependentDocumentTypes +
                            "' business-review-applies=" + businessReviewApplies + " qrm-review-applies=" + qrmReviewApplies + " compliance-review-applies=" + complianceReviewApplies + ">" +
                             documentTypeTitle + "</button></div>";
           }
        }else{
            htmlData = "<span class='modal-span'>There are no more available Document Types.</span>";
        }
        $('#additional-document-types').html(htmlData);
        $('body').css('overflow','hidden');  
        $('#additionalDocumentTypes-modal').show();
        $('#additionalDocumentTypes-modal').css('opacity', '1');;
        clickAdditionalDocumentTypesModalButton();
        closeAdditionalDocumentTypesModal(button);
        
    });
}

$("#additionalDocumentTypes-modal").off('click').on('click', '.btn-add-document-type', addAdditionalDocumentTypeEvent);

$('#s4-workspace').on('click', '.mark-as-final', showMarkAsFinalModal);

function showMarkAsFinalModal(e){
    if($(this).hasClass('marked-as-final') === false){
        var formId = $(this).data('id');
        var fileName = $(this).data('file-name');
        var documentTypeId = $(this).data('document-type-id');
        $('#confirm-modal .modal-footer button.confirmation-modal-button').data('id',  formId).data('file-name', fileName ).data('document-type-id', documentTypeId);
        $("#confirm-modal .modal-body")[0].innerHTML = markAsFinalVersionMessage;
        confirmModalAction(setNewFinalVersion, remenviromentoveMarkAsFinalModalData);
    }
}

function setNewFinalVersion(){
    var okButton = $('#confirm-modal .modal-footer button.confirmation-modal-button');
    var documentTypeId = okButton.data('document-type-id');
    var formId = okButton.data('id');
    var fileName = okButton.data('file-name');
    var folderUrl = createFolderUrl (attachEnviroment, formId);
    var url = getFileByServerRelativeUrl(folderUrl,fileName);
    var oldFinalFileName = $('#attachment-section-document-type-'+documentTypeId+' a.mark-as-final.marked-as-final').data('file-name');

    $.when(updateMarkedAsFinalStatus(url, true)).done(function(){
        if(oldFinalFileName !== undefined){
            var oldFinalButton = $('.mark-as-final[data-file-name="'+oldFinalFileName+'"]');
            var oldFormId = oldFinalButton.data('id');
            var oldFolderUrl = createFolderUrl (attachEnviroment, oldFormId);
            var oldUrl = getFileByServerRelativeUrl(oldFolderUrl,oldFinalFileName);

            $.when(updateMarkedAsFinalStatus(oldUrl, false)).done(function(){
                removeMarkAsFinalModalData();
                setNewFinalVersionStyles(documentTypeId, fileName);
            });
        }else{
            removeMarkAsFinalModalData();
            setNewFinalVersionStyles(documentTypeId, fileName);
        }
    });
}

function removeMarkAsFinalModalData(){
    $('#confirm-modal .modal-footer button.confirmation-modal-button').removeAttr('data-id').removeAttr('data-file-name').removeAttr('document-type-id')
}

function setNewFinalVersionStyles(documentTypeId, fileName){
    var elements = $("#attachment-section-document-type-"+documentTypeId+" .mark-as-final");
    elements.each(function(i, el){
        if($(el).hasClass('marked-as-final')){
            $('[data-file-name="'+$(el).data('file-name')+'"]+span').remove();
        }
        $(el).removeClass('marked-as-final');
        if(elements.length - 1 === i ){
            var newMarkedAsFinalButton = $('a.mark-as-final[data-file-name="'+fileName+'"]');
            newMarkedAsFinalButton.addClass('marked-as-final');
            $('<span class="tooltiptext-dcf marked-as-final-tooltip">Marked as final version</span>').insertAfter(newMarkedAsFinalButton);
        }
    });
}

function addAdditionalDocumentTypeEvent(e){
    e.preventDefault();
    var button = $(this);
    disableClickAddAdditionalDocumentType(button);
    readDocumentInfoFromButton(button, true);
}

function readDocumentInfoFromButton(button, userTriggeredEvent){
    var documentTypeId = button.attr("document-type-id");
    var documentTypeTitle = button.attr("document-type-title");
    var internalId = button.attr("document-internal-id");
    var businessReviewApplies = button.attr("business-review-applies");
    var qrmReviewApplies = button.attr("qrm-review-applies");
    var complianceReviewApplies = button.attr("compliance-review-applies");
    var dependentDocumentTypes = button.attr("dependent-document-types");
    var requiresFinalVersion = button.attr("requires-final-version");
    var guidance = button.attr("guidance");
    if(documentTypeId!=undefined)
        addAdditionalDocumentType(documentTypeId,documentTypeTitle, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, userTriggeredEvent);
}

function readDependentDocumentsInfo(userTriggeredEvent, dependentDocumentTypes, internalId) {
    console.log('readDependentDocumentsInfo')
    if(userTriggeredEvent && dependentDocumentTypes != "") { 
        validateDependentDocumentKeys(dependentDocumentTypes, internalId).forEach(function(dt)  {
            readDocumentInfoFromButton($('button[document-internal-id="'+dt+'"]'), false);
        });
    }
}

function validateDependentDocumentKeys(dependentDocumentTypes, internalId) {
    var validatedKeys = [];

    dependentDocumentTypes.split(',').forEach(function(id){
        var trimedId = id.trim();
        if(trimedId != "" &&  /^[0-9]*$/.test(trimedId) && trimedId != internalId ) {
            if(validatedKeys.indexOf(trimedId) === -1) {
                validatedKeys.push(trimedId);
            }
        }
    });

    return validatedKeys;
}

function disableClickAddAdditionalDocumentType(button) {
   button.removeClass('btn-add-document-type');
}

function addAdditionalDocumentType(documentTypeId,documentTypeTitle, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, userTriggeredEvent) {
    $.when(apiPostAdditionalDocumentType(documentTypeId)).then(function(){
        var isAdditionalDocumentType = true;
        generateDocumentTypeHtml(documentTypeTitle, documentTypeId, internalId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies, dependentDocumentTypes, requiresFinalVersion, guidance, isAdditionalDocumentType);
        $("[document-title='" + documentTypeTitle + "']").removeClass("no-cursor-pointer");
        $("[document-title='" + documentTypeTitle + "']").addClass("cursor-pointer");
        $("#additionalDocumentTypes-modal").find('.close-button-addtional-documents-type-modal').click();
        reviewProcessContext = createReviewProcessContext();
        $.when(reviewProcessContext.calculateReview(gblFormId)).done(function(reviewProcessContextData){
            setReviewProcessStatus(gblFormId, reviewProcessContextData);
            removeSubmitSeries24Button();
            readDependentDocumentsInfo(userTriggeredEvent, dependentDocumentTypes, internalId);
        });
    });
}

function allFinalVersionAreMarked(){
    var requiredFinalVersions = $('.requires-final-version').not('button').length;
    var markedFinalVersion = 0;
    $('.mark-as-final').each(function(i, e){
        $(e).hasClass('marked-as-final') &&  markedFinalVersion++;
    });
    return requiredFinalVersions === markedFinalVersion;
}

function apiPostAdditionalDocumentType(documentTypeId) {   
   
    var deferred = $.Deferred();
    var jsonItem={};
    jsonItem['FormId'] = gblFormId;
    jsonItem['CreatedBy'] = currentUser.userId
    var documentType='';
    // if(documentTypeList != undefined && documentTypeList.length > 0)
    // {
    //     var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
    //     if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
    //         documentType = docTypeObj[0].DocumentTypeTitle;
    // }
    if(additionalDocTypesList != undefined && additionalDocTypesList.length > 0)
    {
        var docTypeObj = additionalDocTypesList.filter(obj => obj.DocumentTypeId == documentTypeId);
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
            documentType = docTypeObj[0].DocumentTypeTitle;
        }
    jsonItem['DocumentType'] = documentType;
    jsonItem['Email'] = currentUser.email;
    jsonItem['ProjectName'] = xbProjectName;
    $.ajax({
        url: apiUrl + "CrossBorderAdditionalDocumentTypes/Add",
        type: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+localStorage.getItem("idToken"),
            "FormType" : localStorage.getItem("FormType"),
            "UserRoles": getUserRoles()
        },
        data: JSON.stringify(jsonItem),
        success: function(data){
            //data = data.filter(obj=>obj.Required=='FALSE')
            if(isXBAdmin)
            {
                $("#AuditFormButton").prop('disabled',false);
            }
            deferred.resolve(data);
        },
        error: function(data){
            showError(data);
        }
    })
    return deferred.promise();
}
 
function series24ReturnForReworkAction() {
    var deferred = $.Deferred();
    // var briefingPaperDocumentType = $("[document-internal-id='" + briefingPaperDocumentTypeId + "']").closest(".row");
    // var reviewItemId = briefingPaperDocumentType.find($("[review-item-id]")).attr("review-item-id");
    var documentType="";
      if(documentTypeList != undefined && documentTypeList.length > 0)
      {
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == briefingPaperDocumentTypeId);
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
        documentType = docTypeObj[0].DocumentTypeTitle;
      }   
        $.when(getReviewStatusItem(gblFormId,documentType)).then(function(statusItemData){
            $.when(deleteReviewStatusItem(statusItemData[0].ID)).done(function() {
                var reviewersStatus = {
                    'businessStatus' :  reviewInProgressStatus,
                    'qrmStatus' : reviewInProgressStatus,
                    'complianceStatus' : reviewInProgressStatus,
                    'businessReturnedForRework' : "NO",
                    'qrmReturnedForRework' : "NO",
                    'complianceReturnedForRework' : "NO",
                    'series24ReturnedForRework' : "YES"
                }
                $.when(apiPutReviewerStatus(gblFormId, reviewersStatus)).done(function () {
                    deferred.resolve(true);
                })
            });
        });
    

    return deferred.promise();
}

//Reviews N/A
function generateNAReviews(documentTypeId, businessReviewApplies, qrmReviewApplies, complianceReviewApplies) {
    var deferred = $.Deferred();
    var status = 'N/A';
    var reviewDate = new Date().toISOString();
    var documentType="";
    if(documentTypeList != undefined && documentTypeList.length > 0)
    {
      var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
      if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
      documentType = docTypeObj[0].DocumentTypeTitle;
    } 
    var jsonItem = {};
        var reviewStatusId = status;
        if (businessReviewApplies == 'false') {
            jsonItem['BusinessReviewStatus'] = reviewStatusId;
            jsonItem['BusinessReviewDate'] = reviewDate;
            jsonItem['BusinessReviewer'] = currentUser.userId;
        }
        if (qrmReviewApplies  == 'false') {
            jsonItem['QRMReviewStatus'] = reviewStatusId;
            jsonItem['QRMReviewDate'] = reviewDate;
            jsonItem['QRMReviewReviewer'] = currentUser.userId;
        }
        if (complianceReviewApplies  == 'false') {
            jsonItem['ComplianceReviewStatus'] = reviewStatusId;
            jsonItem['ComplianceReviewDate'] = reviewDate;
            jsonItem['ComplianceReviewer'] = currentUser.userId;
        }

        jsonItem['FormId'] =gblFormId;
        jsonItem['DocumentType'] = documentType;
        jsonItem['Createdby'] = currentUser.userId;
        jsonItem['AdditionalReviewNeeded'] ="FALSE";
       
        apiPostNAReview(jsonItem).done(function(data){
            var reviewStatusRow = $(".review-status-row-document-type-" + documentTypeId);
                    reviewStatusRow.attr("review-item-id",data.ID);
            
            deferred.resolve();
        });
    
    return deferred.promise();
}

//Additional Review needed functions
function saveIfNeedAdditionalReviewFromQRMCompliance(documentTypeId){
    var deferred = $.Deferred();
    var reviewItem = $(".review-status-row-document-type-" + documentTypeId);
    if(needAdditionalReviewFromQRMCompliance(reviewProcessContext,documentTypeId)){
        var businessReviewApplies = reviewItem.parent().siblings().find(".document-type-button").attr("business-review-applies");
        if(businessReviewApplies !== "false") {
            var reviews = $(".open-boxes-document-type-" + documentTypeId);
            //var reviewBusiness = new ReviewIconHelper().findBusinessReviews(reviews);
            var reviewBusinessQRMCompliance = new ReviewIconHelper().findBusinessQRMComplianceReviews(reviews);
            // var reviewList = generateReviewObjectsToDelete(reviewBusiness);
            var reviewList = generateReviewObjectsToDelete(reviewBusinessQRMCompliance);
            var reviewItemId = reviewItem.attr("review-item-id");
            var documentType="";
      if(documentTypeList != undefined && documentTypeList.length > 0)
      {
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
        if(docTypeObj != undefined && docTypeObj != null)
        documentType = docTypeObj[0].DocumentTypeTitle;
      }   
        $.when(getReviewStatusItem(gblFormId,documentType)).then(function(statusItemData){
            reviewItemId = statusItemData[0].ID;
            $.when(apiPutReviewActionReset(reviewList,documentType,reviewItemId,additionalReviewNeededYes)).done(function(){
                var isReviewProcessCompleted = reviewProcessContext.isDocumentReviewProcessComplete();
                reviewItem.attr("additional-review-needed",additionalReviewNeededYes);
                replaceReviewIcon(reviewBusinessQRMCompliance);
                reviewProcessContext = createReviewProcessContext();
                $.when(reviewProcessContext.calculateReview(gblFormId)).done(function(reviewProcessContextData){
                    $.when(setReviewProcessStatus(gblFormId, reviewProcessContextData)).done(function() {
                        if(isReviewProcessCompleted === reviewedStatus) {
                            location.reload(true);
                        }
                        deferred.resolve();
                    });
                });
            })
        });
        
        }
    } else {
        deferred.resolve();
    }
    return deferred.promise();
}

function setNeedAdditionalReviewFromQRMCompliance(checked){
    $("[name=AdditionalQRMComplianceReviewNeeded]").prop("checked",checked);
}

function enableAdditionalQRMComplianceReviewNeededOption(openbox,documentTypeId){
    setNeedAdditionalReviewFromQRMCompliance(false); 
    var role = openbox.find("button").attr("role");
    var reviewRow = openbox.closest(".review-status-row-document-type-" + documentTypeId);
    var spanReviewText = document.getElementById("spanReviewText");
    // change text for popup for prescreener checkbox
    if (role == "QRM") {
        spanReviewText.innerText = "Prescreened";
    } else {
        spanReviewText.innerText = "Reviewed";
    }
    if(reviewRow.length > 0){
        var additionalReviewNeeded = reviewRow.attr("additional-review-needed");
        if(additionalReviewNeeded == additionalReviewNeededYes && role == "Business") {
            $("#AdditionalQRMComplianceReviewNeeded").removeClass("hidden");
        } else {
            $("#AdditionalQRMComplianceReviewNeeded").addClass("hidden")
        }
    }
}

function isAdditionalQRMComplianceReviewNeededEnabled(){
    return !($("#AdditionalQRMComplianceReviewNeeded").hasClass("hidden"));
}

function needAdditionalReviewFromQRMCompliance(reviewProcessContext,documentTypeId){      
    var reviewButtons = $(".open-boxes-document-type-" + documentTypeId).find("button");        
    for(var i = 0 ; i < reviewButtons.length; i++){
        var reviewButton = $(reviewButtons[i]);
        var role = reviewButton.attr("role");
        var status = reviewButton.attr("status");
        if(typeof(status) != "undefined" && role != "Business" && reviewProcessContext.isReviewValid(status)){
            return true;
        }
    }
    return false;
}
//end Additional Review needed functions
function ReviewProcessContext(totalPossibleReviews, totalPossibleReviewsByRole) {
    this.totalBusinessReviews = 0;
    this.totalQRMReviews = 0;
    this.totalComplianceReviews = 0;
    //Total reviews including Returned for Rework for sharepoint views
    this.totalBusinessReviewsView = 0;
    this.totalQRMReviewsView = 0;
    this.totalComplianceReviewsView = 0;

    this.currentTotalReviews = 0;
    this.totalReviews = totalPossibleReviews;
    this.totalReviewsByRole = totalPossibleReviewsByRole;
    this.businessReviewsReturnedForReworkView = "NO";
    this.qrmReviewsReturnedForReworkView = "NO";
    this.complianceReviewsReturnedForReworkView = "NO";
    this.isTheLastReview = function () {
        return this.totalReviews == this.currentTotalReviews + 1;
    };
    this.isReviewValid = function (reviewStatus) {
        return reviewStatus == "Reviewed" || reviewStatus == "N/A";
    };
    this.isReviewReturnedForRework = function (reviewStatus) {
        return reviewStatus == "Returned For Rework";
    };
    this.isDocumentReviewProcessComplete = function () {
        if (this.totalReviews == this.currentTotalReviews) {
            return "Reviewed";
        }
        return "Pending";
    };

    this.isBusinessReviewCompletedView = function () {
        if (this.totalReviewsByRole == this.totalBusinessReviewsView) {
            if (this.businessReviewsReturnedForReworkView === "YES") {
                return "Reviewed - Returned for Rework";
            } else if(this.totalReviewsByRole != 0 && this.totalBusinessReviewsView !=0){
                return "Reviewed";
            }
            else
                return "Review in Progress";
        }
        return "Review in Progress";
    };

    this.isQRMReviewCompletedView = function () {
        if (this.totalReviewsByRole == this.totalQRMReviewsView) {
            if (this.qrmReviewsReturnedForReworkView === "YES") {
                return "Reviewed - Returned for Rework";
            } else if(this.totalReviewsByRole != 0 && this.totalQRMReviewsView !=0) {
                return "Reviewed";
            }
            else
                return "Review in Progress";
        }
        return "Review in Progress";
    };

    this.isComplianceReviewCompletedView = function () {
        if (this.totalReviewsByRole == this.totalComplianceReviewsView) {
            if (this.complianceReviewsReturnedForReworkView === "YES") {
                return "Reviewed - Returned for Rework";
            } else if(this.totalReviewsByRole != 0 && this.totalComplianceReviewsView !=0){
                return "Reviewed";
            }
            else
                return "Review in Progress";
        }
        return "Review in Progress";
    };
    var self = this;
    this.calculateReview = function (formId,reviewData) {
        var deferred = $.Deferred();
        if(reviewData!=undefined && reviewData!=null )
        {
            var reviews = reviewData;
            var ajaxCalls = 0;
            self.CalculateTotalReviewsView(reviews);
            $.each(reviews, function (index, value) {
                if (self.isReviewValid(value.BusinessReviewStatus)) {
                    self.currentTotalReviews++;
                    self.totalBusinessReviews++;
                } 
                if (self.isReviewValid(value.QRMReviewStatus)) {
                    self.currentTotalReviews++;
                    self.totalQRMReviews++;
                }
                if (self.isReviewValid(value.ComplianceReviewStatus)) {
                    self.currentTotalReviews++;
                    self.totalComplianceReviews++;
                }
                ajaxCalls++;
            });
            if (ajaxCalls == reviews.length) {
                deferred.resolve(self);
            }
            return deferred.promise();
        }
        else
        {
            $.when(getDocumentStatus(formId)).then(function (data) {
                var reviews = data;
                var ajaxCalls = 0;
                self.CalculateTotalReviewsView(reviews);
                $.each(reviews, function (index, value) {
                    if (self.isReviewValid(value.BusinessReviewStatus)) {
                        self.currentTotalReviews++;
                        self.totalBusinessReviews++;
                    } 
                    if (self.isReviewValid(value.QRMReviewStatus)) {
                        self.currentTotalReviews++;
                        self.totalQRMReviews++;
                    }
                    if (self.isReviewValid(value.ComplianceReviewStatus)) {
                        self.currentTotalReviews++;
                        self.totalComplianceReviews++;
                    }
                    ajaxCalls++;
                });
                if (ajaxCalls == reviews.length) {
                    deferred.resolve(self);
                }
           }); 
           return deferred.promise();
        }
       
    };

    this.CalculateTotalReviewsView = function (reviews) {
        $.each(reviews, function (index, value) {
            if (self.isReviewValid(value.BusinessReviewStatus)) {
                self.totalBusinessReviewsView++;
            } else if (self.isReviewReturnedForRework(value.BusinessReviewStatus)) {
                self.totalBusinessReviewsView++;
                self.businessReviewsReturnedForReworkView = "YES";
            }
            if (self.isReviewValid(value.QRMReviewStatus)) {
                self.totalQRMReviewsView++;
            }else if (self.isReviewReturnedForRework(value.QRMReviewStatus)) {
                self.totalQRMReviewsView++;
                self.qrmReviewsReturnedForReworkView = "YES";
            }
            if (self.isReviewValid(value.ComplianceReviewStatus)) {
                self.totalComplianceReviewsView++;
            }else if (self.isReviewReturnedForRework(value.ComplianceReviewStatus)) {
                self.totalComplianceReviewsView++;
                self.complianceReviewsReturnedForReworkView = "YES";
            }
        });
    }
}

function ReviewIcon(icon) {
    this.icon = icon;
    this.isPendingIcon = function () {
        return icon.hasClass("fa-clock-o");
    };
    this.isNaIcon = function () {
        return icon.hasClass("glyphicon-log-out");
    };
    this.isReviewedIcon = function () {
        return icon.hasClass("fa-check-circle");
    };
    this.isReturnedForReworkIcon = function () {
        return icon.hasClass("fa-times-circle");
    };
    this.isBusiness = function () {
        return icon.find("button").attr("role") == "Business";
    };
    this.isQRM = function () {
        return icon.find("button").attr("role") == "QRM";
    };
    this.isCompliance = function () {
        return icon.find("button").attr("role") == "Compliance";
    };
}

function ReviewIconHelper() {
    this.findBusinessReviews = function (reviewIcons) {
        var reviewList = reviewIcons.find(".check-status");
        var result = [];
        $.each(reviewList, function () {
            var review = $(this);
            var role = review.find("button").attr("role");
            if (role == "Business") {
                result.push(review);
            }
        });
        return result;
    };
    //  To get all reviews for  given document type
    this.findBusinessQRMComplianceReviews = function (reviewIcons) {
        var reviewList = reviewIcons.find(".check-status");
        var result = [];
        $.each(reviewList, function () {
            var review = $(this);
            var role = review.find("button").attr("role");
            if (role == "Business" || role == "QRM" || role == "Compliance") {
                result.push(review);
            }
        });
        return result;
    };
    this.findQRMAndComplianceReviews = function (reviewIcons) {
        var reviewList = reviewIcons.find(".check-status");
        var result = [];
        $.each(reviewList, function () {
            var review = $(this);
            var role = review.find("button").attr("role");
            if (role == "QRM" || role == "Compliance") {
                result.push(review);
            }
        });
        return result;
    };
    this.findReturnedForReworkReviews = function (reviewIcons) {
        return reviewIcons.find(".fa-times-circle");
    };
    this.findReviewedNaPendingReviews = function (reviewIcons) {
        return reviewIcons.find(".fa-check-circle, .glyphicon-log-out, .fa-clock-o");
    };
}
