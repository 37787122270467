var autoSaveEnabled = false;
var mayorRole = false;
var engagementTeam, dealTeamBusinessReviewer,internationalMember,series24,internationalMemberDisplayName,Series24DisplayNames;
function hideApproverButtons() {
    $('#IndustryRepresentativeButtons').addClass('hidden');
}

function showApproverButtons() {
    $('#IndustryRepresentativeButtons').removeClass('hidden');
}

function showSeries24Buttons() {
    $('#series24Buttons').removeClass('hidden');
}

function showFunctionalButtons() {
    $('#functional-buttons-save').removeClass('hidden');
}

function hideFunctionalButtons() {
    $('#functional-buttons-save').addClass('hidden');
}

function showDCFComplianceBox() {
    $("#dcfComplianceBox").removeClass('hidden')
}

function showSeries24Box() {
    $("#series24Box").removeClass('hidden')
}

function showSaveDCFEngagementTeamBox() {
    $("#save-dcf-admin-box").removeClass('hidden')
}

function showReviewersButtonsViews() {
    $("#reviewers-buttons-views").removeClass('hidden');
}

function generateReviewersButtonsViews(securityContext) {
    if (securityContext.isBusinessReviewer) {
        generateButton(businessView, businessViewButtonText);
    }
    if (securityContext.isQRMReviewer) {
        generateButton(qrmView, qrmViewButtonText);

    }
    if (securityContext.isComplianceReviewer) {
        generateButton(complianceView, complianceViewButtonText);

    }
    showReviewersButtonsViews();
}

function generateUrlReviewersViews(view) {
    return apiUrl + 'Lists/' + enviroment + '/' + view;
}

function generateButton(view, buttonText) {
    var htmlButton;
    var selector = $("#reviewers-buttons-views");

    htmlButton = '<a href="' + generateUrlReviewersViews(view) + '"><div class="reviewers-view-button btn btn-outline-primary">' + buttonText + '</div></a>';
    selector.append(htmlButton);
}

function enableIndustryRepresentativeComments() {
    $(".IndustryRepresentativeComments").prop('disabled', false)
}

function enableSeries24Comments() {
    $(".Series24Comments").prop('disabled', false)
}

function showDiscontinueButton() {
    $('.discontinue-button').removeClass('hidden');
}

function showOnHoldButton() {
    $('.on-hold-button').removeClass('hidden');
}

function showFootIndustryRepresentative() {
    $('.foot-industry-approver').removeClass('hidden');
}

function showFoots24() {
    $('.foot-s24-approver').removeClass('hidden');
}

function showFootDiscontinuedForm() {
    $('.foot-discontinued-form').removeClass('hidden');
}

function showFootOnHoldForm() {
    $('.foot-on-hold-form').removeClass('hidden');
}

function hideInternalComments() {
    $(".internal-comment").addClass("hidden");
}

function showSubmitSeries24Button() {
    $('#submitS24Button').removeClass("hidden");
}

function hideSubmitSeries24Button() {
    $('#submitS24Button').addClass("hidden");
}

function removeSubmitSeries24Button() {
    $('#submitS24Button').remove();
    localStorage.removeItem("UploadFileInProgress");
}

function showAsterikPeoplePicker() {
    $.each($(".pp-editable-by-condition"), function () {
        var pp = $(this);
        var parentItem = pp.parents(".required-div");
        parentItem.find(".red-asterik").removeClass("visibility-hidden");
    })
}
function showAsterikChosenField() {
    $.each($(".chosen-select-no-results"), function () {
        var cc = $(this);
        var parentItem = cc.parents(".required-div");
        parentItem.find(".red-asterik").removeClass("visibility-hidden");
    })
}

function enableRiskRating() {
    $(".risk-selector-option").prop("disabled", false);
}

function disabledRiskRatingBox() {
    $(".risk-selector-option").prop("disabled", true);
}

function enableDflow() {
    $("[name='DFLOW']").prop("disabled", false);
}

function disableDflow() {
    $("[name='DFLOW']").prop("disabled", true);
}

function disableAttachDocuments() {
    $("#s4-workspace").off('click', '.open-selector-file');
    $(".open-selector-file").addClass("no-cursor-pointer");
}

function enableAttachDocuments() {
    disableAttachDocuments();
    $("#s4-workspace").on('click', '.open-selector-file', openSelectorFileAttachments);
    $(".open-selector-file").removeClass("no-cursor-pointer");
}
function disableIncomeSectionAttachDocuments() {
    $("#s4-workspace").off('click', '.open-selector-file-income');
    $(".open-selector-file-income").addClass("no-cursor-pointer");
}
function enableIncomeSectionAttachDocuments() {
    disableIncomeSectionAttachDocuments();
    $("#s4-workspace").on('click', '.open-selector-file-income', openSelectorFileAttachments);
    $(".open-selector-file-income").removeClass("no-cursor-pointer");
}

function enableAttachReturnedDocumentsForMemberFirm() {
    $.each($(".open-selector-file[document-title='Cross-Border Briefing Paper']"), function () {

        var reviewForReturn = $(this);
        var row = reviewForReturn.closest(".row");
        var selectorFile = row.find(".open-selector-file");
        selectorFile.removeClass("no-cursor-pointer");
        selectorFile.on('click', openSelectorFileAttachmentsForMemberFirm);
    })
}

function enableAttachAdditionalDocumentsFormMemberFirm() {
    $.each($(".open-selector-file[additional-document-type='true']"), function () {
        var selectorFile = $(this);
        selectorFile.removeClass("no-cursor-pointer");
        selectorFile.on('click', openSelectorFileAttachmentsForMemberFirm);
    })
}

function disableDeleteAttachmentOption() {
    $('.delete-uploaded-file').addClass("visibility-hidden");    
}

function disableDeleteAttachmentOptionForNewQuestions() {
     $('.delete-uploaded-fin-capitalizations-file').addClass("visibility-hidden");
}
function enableDeleteAttachmentOptionForNewQuestions() {
     $('.delete-uploaded-fin-capitalizations-file').removeClass("visibility-hidden");
}

function enableDeleteAttachmentOptionForDCF() {
    $('.delete-uploaded-file').removeClass("visibility-hidden");    
}

function enableDeleteAttachmentOption(reviewData) {
    $.each($(".fa-times-circle"), function () {


        var reviewForReturn = $(this);
        var row = reviewForReturn.closest(".row");
        var documentTypeId = row.find("input[type=file]").attr("document-type");
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
          if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
            {
                var documentType = docTypeObj[0].DocumentTypeTitle;
                if(documentType && reviewData.find(x=>(x.DocumentType==documentType) &&(x.QRMReviewStatus=="Returned For Rework" || x.ComplianceReviewStatus=="Returned For Rework"|| x.BusinessReviewStatus=="Returned For Rework"))!=undefined)
                {
                    $("#attachment-section-document-type-" + documentTypeId).find(".delete-uploaded-file").removeClass("visibility-hidden");
                }
            }
        
    })
}

function disableAttachRedLinesDocuments() {
    $(".modal-body-review-assistant").off('click', '.open-selector-file');
    $(".redline-attachment-section-modal").find(".open-selector-file").addClass("no-cursor-pointer");
}

function enableAttachRedLinesDocuments() {
    disableAttachRedLinesDocuments();
    $(".redline-attachment-section-modal").find(".open-selector-file").removeClass("no-cursor-pointer");
}

function disableClickOpenBoxes() {
    var openBoxes = $(".attachments-rows").find('.review-assistant-open-box');
    openBoxes.removeClass("cursor-pointer review-assistant-open-box-click");
    openBoxes.addClass("no-cursor-pointer");

    var pendingIcons = $(".attachments-rows").find('.pending-icon-color');
    pendingIcons.removeClass("cursor-pointer pending-review-icon");
    pendingIcons.addClass("no-cursor-pointer");

    var returnedIcons = $(".attachments-rows").find('.return-for-rework-icon-color');
    returnedIcons.removeClass("cursor-pointer return-review-icon");
    returnedIcons.addClass("no-cursor-pointer");
}

function enableClickOpenBoxesPerRole(role) {
    var reviewPerRole = $(".attachments-rows").find($("[role='" + role + "']")).parent();

    $.each(reviewPerRole, function () {
        var review = $(this);
        review.removeClass("no-cursor-pointer");
        if (review.hasClass("review-assistant-open-box")) {
            review.addClass("cursor-pointer review-assistant-open-box-click");
        } else {
            if (review.hasClass("pending-icon-color")) {
                review.addClass("cursor-pointer pending-review-icon");
            } else {
                if (review.hasClass("fa-times-circle")) {
                    review.addClass("cursor-pointer return-review-icon");
                }
            }
        }
    })
}

function disableDeleteRedLinesOption(documentTypeId) {
    $(".redline-attachment-section-" + documentTypeId).find(".delete-uploaded-file").addClass("visibility-hidden");
}

function enableDeleteRedLinesOption(reviewData) {
    $.each($(".fa-times-circle"), function () {
        var reviewForReturn = $(this);
        var row = reviewForReturn.closest(".row");
        var documentTypeId = row.find("input[type=file]").attr("document-type");
        var docTypeObj = documentTypeList.filter(obj => obj.DocumentTypeId == documentTypeId);
        if(docTypeObj != undefined && docTypeObj != null && docTypeObj.length>0)
        {
            var documentType = docTypeObj[0].DocumentTypeTitle;            
            if(documentType && reviewData.find(x=>(x.DocumentType==documentType) &&(x.QRMReviewStatus=="Returned For Rework" || x.ComplianceReviewStatus=="Returned For Rework"|| x.BusinessReviewStatus=="Returned For Rework"))!=undefined)
            {
            $(".redline-attachment-section-" + documentTypeId).find(".delete-uploaded-file").removeClass("visibility-hidden");
           }
        }
        
    })
}

function disableTextAreaComments() {
    $("[name='CommentsReviewAssistant']").prop("disabled", true);
}

function enableTextAreaComments() {
    $("[name='CommentsReviewAssistant']").prop("disabled", false);
}

function disableMakeReview() {
    $("#review-assistant-modal").find(".review-action").removeClass("review-assistant-buttons-click");
}

function enableMakeReview() {
    $("#review-assistant-modal").find(".review-action").addClass("review-assistant-buttons-click");
}

function hideAddAdditionalDocumentsRow() {
    $("#addAdditionalCommentsRow").addClass("hidden");
    $(".hr-additional-comments").addClass("hidden");
}

function showAddAdditionalDocumentsRow() {
    $("#addAdditionalCommentsRow").removeClass("hidden");
    $(".hr-additional-comments").removeClass("hidden");
}

function addClassRequiredInput(name) {
    $("[name='" + name + "']").addClass("required");
}

function removeClassRequiredInput(name) {
    $("[name='" + name + "']").removeClass("required");
}

function showApprovalFooters() {
    if ($('[name=IndustryRepresentativeSignature]').val()) {
        showFootIndustryRepresentative();
        $(".IndustryCommentsAfterApproved").val($(".IndustryRepresentativeComments").val());
    }
    if ($('[name=Series24Signature]').val()) {
        showFoots24();
        $(".Series24CommentsAfterApproved").val($(".Series24Comments").val())
    }
}

function showRedAsterikToInput(input) {
    var parent = input.closest(".required-div");
    parent.find(".red-asterik").removeClass("visibility-hidden");
    input.addClass("required");
}

function showAttachmentSection() {
    $(".attachment-section").removeClass("hidden");
}

function hideAttachmentSection() {
    $(".attachment-section").addClass("hidden");   
}

function hideAttachRecommendedButton() {
    $(".attachRecommendedButton").addClass("hidden");
}

function showAttachRecommendedButton() {
    $(".attachRecommendedButton").removeClass("hidden");
}

function addPaddingToAttachmentSection() {
    $(".attachment-section").addClass("padding-bottom-20");
}

function showSubmitToS24() {
    showFunctionalButtons();
    removeHiddenClassOnSaveButton();
    $(".formsub").addClass('hidden');
    showSubmitSeries24Button();
}

function removeHiddenClassOnSaveButton() {
    $('.draftsub').removeClass('hidden');
}

function enableBusinessQuestionnaire() {
    var questionElements = $(".businessQuestionnaire");
    questionElements.prop("disabled", false);
    questionElements.addClass("input-data-text");
}

function disableBusinessQuestionnaire() {
    var questionElements = $(".businessQuestionnaire");
    questionElements.prop("disabled", true);
    questionElements.removeClass("input-data-text");
    disableEnterpriseInputs();
}

function disableReviewSection() {
    disabledRiskRatingBox();
    disableDflow();
    disableAttachDocuments();
    hideAddAdditionalDocumentsRow();
    disableDeleteAttachmentOption();
    disableClickOpenBoxes();
    disableMakeReview();
    disableAttachRedLinesDocuments();
    disableDeleteRedLinesOption();
    disableTextAreaComments();
    // To enable document upload fields in income statement section
    if (gblFormStatus == "Draft" || gblFormStatus == "Pending") {
        enableIncomeSectionAttachDocuments();
    }
    // if (gblFormStatus == "Pending") {
    //     enableDflow();
    // }
}

function initializeSections(isMemberFirmMember) {
    if (isMemberFirmMember) {
        hideInternalComments();
    }
    hideFunctionalButtons();
    hideApproverButtons();
    hideSubmitSeries24Button();
    disableAllChosenFields();
    disableReviewSection();
    showApprovalFooters();
    hideAttachmentSection();
    hideAttachRecommendedButton();
    setAutoSave(appContext, false);
}

function disableFormActionsAfterDocumentReviewProcessComplete() {
    hideFunctionalButtons();
    hideApproverButtons();
    hideSubmitSeries24Button();
    disableAllChosenFields();
    disableReviewSection();
}

function applySecurity(securityContext, reviewProcessContext, formStatus, formActionGroup,reviewData) {
    initializeSections(securityContext.isMemberFirmMember);
    var readOnlyState = new ReadOnlyFormState();
    switch (formStatus) {
        case 'Draft':
            showDraftForm(securityContext.isSiteAdmin, securityContext.isMemberFirmMember, readOnlyState);
            break;

        case 'Completed':
            showCompletedForm(readOnlyState);
            break;

        case 'Pending':
            showPendingForm(formActionGroup, securityContext, reviewProcessContext, readOnlyState,reviewData);
            break;

        case 'Discontinued':
            showDiscontinuedForm(securityContext.isSiteAdmin, readOnlyState);
            break;

        case 'OnHold':
            showOnHoldForm(securityContext.isSiteAdmin, readOnlyState);
            break;
    }
    
    isPeoplePickerLoad=false;
}

//what to show when the form is set on draft
function showDraftForm(isSiteAdmin, isMemberFirmMember, readOnlyState) {
    if (isSiteAdmin || isMemberFirmMember) {
        showFunctionalButtons();
        showDiscontinueButton();
        showOnHoldButton();
        enableDeleteAttachmentOptionForNewQuestions();        
        autoSaveEnabled = setAutoSave(appContext, true);
        if (!autoSaveEnabled) {
            showSaveButton("#functional-buttons-save .draftsub");
        }
    } else {
        readOnlyState.applyForAllInputExceptAdmSection();
        //Disbale xb form new document section
        disableCLientTypeXB();
        disableIncomeSectionAttachDocuments();
        disableDeleteAttachmentOptionForNewQuestions();
        disableDflow();
    }
}

//what to show when the form is set on complete
function showCompletedForm(readOnlyState) {
    hideFunctionalButtons();
    hideApproverButtons();
    disableDeleteAttachmentOptionForNewQuestions();
    showAttachmentSection();
    addPaddingToAttachmentSection();
    disableCLientTypeXB();
    readOnlyState.applyForAllForm();
    $("#peoplePickerDivMemberFirmTeam").prop('disabled', true).trigger('chosen:updated');
            if(internationalMember!=undefined)
            {
                fillInternationalMemberFirmTeam(internationalMember);
            }  
           // $("#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input").attr("disabled", "disabled"); 
            $('#peoplePickerDivMemberFirmTeam_chosen .search-choice-close').hide();

}

function showDiscontinuedForm(isSiteAdmin, readOnlyState) {
    if (isSiteAdmin) {
        showFootDiscontinuedForm();
    }
    hideFunctionalButtons();
    hideApproverButtons();
    showAttachmentSection();
    disableDeleteAttachmentOptionForNewQuestions();
    addPaddingToAttachmentSection();
    disableCLientTypeXB();
    readOnlyState.applyForAllForm();
    
    $("#peoplePickerDivMemberFirmTeam").prop('disabled', true).trigger('chosen:updated');
    if(internationalMember!=undefined)
    {
        fillInternationalMemberFirmTeam(internationalMember);
    }  
   // $("#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input").attr("disabled", "disabled"); 
    $('#peoplePickerDivMemberFirmTeam_chosen .search-choice-close').hide();

    $("#peoplePickerDivMemberFirmTeam_chosen").removeClass("chosen-disabled"); 
}

function showOnHoldForm(isSiteAdmin, readOnlyState) {
    if (isSiteAdmin) {
        showFootOnHoldForm();
    }
    hideFunctionalButtons();
    hideApproverButtons();
    disableDeleteAttachmentOptionForNewQuestions();
    showAttachmentSection();
    addPaddingToAttachmentSection();
    disableCLientTypeXB();
    readOnlyState.applyForAllForm();
    $("#peoplePickerDivMemberFirmTeam").prop('disabled', true).trigger('chosen:updated');
    if(internationalMember!=undefined)
    {
        fillInternationalMemberFirmTeam(internationalMember);
    }  
   // $("#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input").attr("disabled", "disabled"); 
    $('#peoplePickerDivMemberFirmTeam_chosen .search-choice-close').hide();
    $("#peoplePickerDivMemberFirmTeam_chosen").removeClass("chosen-disabled"); 

}

//what to show when the form is set on pending
function showPendingForm(formActionGroup, securityContext, reviewProcessContext, readOnlyState,reviewData) {
    autoSaveEnabled = setAutoSave(appContext, true);
    mayorRole = false;
    switch (formActionGroup) {
        case 'MemberFirmTeam':
            showFirmMembersForm(securityContext.isMemberFirmMember, securityContext.isSiteAdmin, readOnlyState);
            break;
        case 'IndustryRepresentative':
            showIndustryRepresentativeForm(securityContext, readOnlyState);
            break;
        case 'SecondSubmissionTeam':
            showSecondSubmissionTeamForm(securityContext, readOnlyState);
            break;
        case 'Series24':
            showSeries24Form(securityContext, readOnlyState);
            break;
        case 'BusinessQRMCompliance':
            showBusinessQRMComplianceForm(securityContext, reviewProcessContext, readOnlyState,reviewData);
            break;
    }
    if (formActionGroup !== 'MemberFirmTeam') {
        if (securityContext.isBusinessReviewer || securityContext.isIndustryRepresentative || securityContext.isSiteAdmin) {
            enableDCFAdminBoxPeoplePickers(autoSaveEnabled, mayorRole);
        }
        if(securityContext.isIndustryRepresentative==false && securityContext.isSiteAdmin==false && securityContext.isEngagementTeamMember==false)
        { 
            $("#peoplePickerDivMemberFirmTeam").prop('disabled', true).trigger('chosen:updated');
            if(internationalMember!=undefined)
            {
                fillInternationalMemberFirmTeam(internationalMember);
            }  
           // $("#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input").attr("disabled", "disabled"); 
            $('#peoplePickerDivMemberFirmTeam_chosen .search-choice-close').hide();
        }
        if(formActionGroup=="BusinessQRMCompliance" && (securityContext.isQRMReviewer||securityContext.isBusinessReviewer||securityContext.isComplianceReviewer))
            {
                $("#peoplePickerDivMemberFirmTeam").prop('disabled', false).trigger('chosen:updated');
            if(internationalMember!=undefined)
            {
                fillInternationalMemberFirmTeam(internationalMember);
            }  
            $('#peoplePickerDivMemberFirmTeam_chosen .search-choice-close').show();
            }
        if(formActionGroup=="Series24" && securityContext.isSeries24Member==true)
        {
            $("#peoplePickerDivMemberFirmTeam").prop('disabled', false).trigger('chosen:updated');
            if(internationalMember!=undefined)
            {
                fillInternationalMemberFirmTeam(internationalMember);
            }  
            $('#peoplePickerDivMemberFirmTeam_chosen .search-choice-close').show();
        }
        if(securityContext.isSiteAdmin==true && formActionGroup=="BusinessQRMCompliance")
        {
            if($('#ddlIncomeYear1').is(':disabled')==true)
            {
                $("#peoplePickerDivMemberFirmTeam_chosen .chosen-search-input").attr("disabled", "disabled"); 
                $('#peoplePickerDivMemberFirmTeam_chosen .search-choice-close').hide();
            }
            
        }
        if(formActionGroup=="BusinessQRMCompliance" && (securityContext.isSiteAdmin||securityContext.isBusinessReviewer||securityContext.isIndustryRepresentative))
        {
            $("#Series24_chosen").css("pointer-events","");
        }
       
    }
}

function showFirmMembersForm(isMemberFirmMember, isSiteAdmin, readOnlyState) {
    if (isMemberFirmMember || isSiteAdmin) {
        showFunctionalButtons();
        showDCFComplianceBox();
        showDiscontinueButton();
        showOnHoldButton();
        if (!autoSaveEnabled) {
            showSaveButton("#functional-buttons-save .draftsub");
        }
    } else {
        readOnlyState.applyForAllInputExceptAdmSection();
        //Disbale xb form new document section
        disableCLientTypeXB();
        disableIncomeSectionAttachDocuments();
        disableDeleteAttachmentOptionForNewQuestions();        
    }
}

function showIndustryRepresentativeForm(securityContext, readOnlyState) {
    if (securityContext.isIndustryRepresentative || securityContext.isSiteAdmin) {
        showApproverButtons();
        showDCFComplianceBox();
        enableIndustryRepresentativeComments();
        enableAllPeoplePicker();
        enableAllChosenFields();
        enableDflow();
        showAsterikPeoplePicker();
        showAsterikChosenField();
        enableDeleteAttachmentOptionForNewQuestions();
        mayorRole = true;
        if (!autoSaveEnabled) {
            showSaveButton("#dcfComplianceBox .draftsub");
        }
    } else {
        readOnlyState.applyForAllInputExceptAdmSection();
        //Disbale xb form new document section
        disableCLientTypeXB();
        disableIncomeSectionAttachDocuments();
        disableDeleteAttachmentOptionForNewQuestions();
        if(securityContext.isBusinessReviewer)
        {
            enableDflow();
        }
        else
        {
            disableDflow();
        }        
    }
}

function showMarkAsFinalButton() {
    $('.attachments-rows').addClass('mark-as-final-version-enabled');
    $('.mark-as-final').removeClass('hidden').addClass('enabled');
}

function showSecondSubmissionTeamForm(securityContext, readOnlyState) {
    var autoSaveAllowed = securityContext.isAutoSaveEnabledOnSecondSubmission();

    if (!autoSaveAllowed) {
        readOnlyState.applyForAllInputExceptAdmSection();
        //Disbale xb form new document section
        disableCLientTypeXB();
        disableIncomeSectionAttachDocuments();
        disableDeleteAttachmentOptionForNewQuestions();
    }

    showAttachmentSection();

    if (securityContext.isEngagementTeamMember || securityContext.isSiteAdmin) {
        showFunctionalButtons();
        showDiscontinueButton();
        showOnHoldButton();
        makeDocumentTypesRequired();
        showRedAsterixDocumentTypeSection();
        enableAttachDocuments();
        enableDeleteAttachmentOptionForDCF();
        enableRiskRating();
        //enableDflow();
        showRedAsterikToInput($("#DealTeamBusinessReviewer"));
        showRedAsterikToInput($("#DCFEngagementTeam"));
        showRedAsterikToInput($("#Series24"));
        showMarkAsFinalButton();
        showAddAdditionalDocumentsRow();
        enableDeleteAttachmentOptionForNewQuestions();
        mayorRole = true;
        if (!autoSaveEnabled) {
            showSaveButton("#functional-buttons-save .draftsub");
        }
    }

    if (securityContext.isBusinessReviewer) {
        showMarkAsFinalButton();
    }

    if(securityContext.isIndustryRepresentative || securityContext.isBusinessReviewer || securityContext.isSiteAdmin)
    {
        enableDflow();
    }
    else
    {
        disableDflow();
    }

    if (securityContext.isMemberFirmMember) {
        showFunctionalButtons();
        showDiscontinueButton();
        showOnHoldButton();
        makeDocumentTypesRequired();
        showRedAsterixDocumentTypeSection();
        enableAttachDocuments();
        enableDeleteAttachmentOptionForDCF();
        mayorRole = true;
        if (!autoSaveEnabled) {
            showSaveButton("#functional-buttons-save .draftsub");
        }
    }
}

function setAttachmentsSectionInReviewInProgress(securityContext,reviewData) {
    if (securityContext.canReview() || securityContext.isEngagementTeamMember) {
        enableAttachDocuments();
        enableDeleteAttachmentOption(reviewData);
        enableDeleteRedLinesOption(reviewData);
    } else if (securityContext.isMemberFirmMember) {
        disableAttachDocuments();
        enableAttachReturnedDocumentsForMemberFirm();
        enableAttachAdditionalDocumentsFormMemberFirm();
        enableDeleteAttachmentOption(reviewData);
        enableDeleteRedLinesOption(reviewData);
    }
}

function showAdditionalDocumentTypesButton(securityContext) {
    if (securityContext.canAddAdditionalDocumentTypes()) {
        showAddAdditionalDocumentsRow();
    }
}

function setResourcesToBusinesQRMCompliance(securityContext) {
    if (securityContext.canReview()) {
        //enableDflow();
        enableRiskRating();
        //enableDflow();
        enableMakeReview();
        enableTextAreaComments();
        enableAttachRedLinesDocuments();
        showAdditionalDocumentTypesButton(securityContext);
        showAttachRecommendedButton();
        showDiscontinueButton();
        showOnHoldButton();
        showRedAsterikToInput($("[name=DFCRiskRating]"));
        removeClassRequiredInput("DFCRiskRating");
        showRedAsterikToInput($("#DealTeamBusinessReviewer"));
        showRedAsterikToInput($("#DivDCFEngagementTeam"));
        showRedAsterikToInput($("#Series24"));

    }
}

function enableClickOpenBoxesForRoles(securityContext) {
    if (securityContext.canClickOpenBoxesByBusiness()) {
        enableClickOpenBoxesPerRole("Business");
    }
    if (securityContext.canClickOpenBoxesByQRM()) {
        enableClickOpenBoxesPerRole("QRM")
    }
    if (securityContext.canClickOpenBoxesByCompliance()) {
        enableClickOpenBoxesPerRole("Compliance");
    }
}

function showBusinessQRMComplianceForm(securityContext, reviewProcessContext, readOnlyState,reviewData) {
    var autoSavedAllowed = securityContext.isAutoSaveEnabledOnReviewInProgress();

    showSeries24Box();
    showAttachmentSection();
   //Bug Fix 3711774
    //generateReviewersButtonsViews(securityContext);

    if (securityContext.isEngagementTeamMember || securityContext.isSiteAdmin) {
        enableRiskRating();
        //enableDflow();
        showDiscontinueButton();
        showOnHoldButton();
        enableDeleteAttachmentOptionForNewQuestions();
        mayorRole = true;
    }

    if(securityContext.isIndustryRepresentative || securityContext.isBusinessReviewer || securityContext.isSiteAdmin)
    {
        enableDflow();
    }
    else
    {
        disableDflow();
    }

    if (securityContext.isSiteAdmin || securityContext.isEngagementTeamMember || securityContext.isBusinessReviewer) {
        showMarkAsFinalButton();
    }

    if (!autoSavedAllowed) {
        readOnlyState.applyForAllInputExceptAdmSection();
        //Disbale xb form new document section
        disableCLientTypeXB();
        disableIncomeSectionAttachDocuments();
        disableDeleteAttachmentOptionForNewQuestions();
    }

    if (reviewProcessContext.isDocumentReviewProcessComplete() !== "Reviewed") {
        setAttachmentsSectionInReviewInProgress(securityContext,reviewData);
        setResourcesToBusinesQRMCompliance(securityContext);
        enableClickOpenBoxesForRoles(securityContext);
        if (securityContext.isEngagementTeamMember) {
            showAdditionalDocumentTypesButton(securityContext);
        }
        if (securityContext.canReview() == true) {
            mayorRole = true;
        }
        if (autoSavedAllowed && !autoSaveEnabled) {
            $(".formsub").addClass('hidden');
            showFunctionalButtons();
            showSaveButton("#functional-buttons-save .draftsub");
        }
    } else {
        showFinalSubmissionForm(securityContext, reviewProcessContext, readOnlyState);
    }
}

function showSeries24Form(securityContext, readOnlyState) {
    showAttachmentSection();
    if(securityContext.isIndustryRepresentative || securityContext.isSiteAdmin || securityContext.isBusinessReviewer)
    {
        enableDflow();
    }
    else
    {
        disableDflow();
    }
    if (securityContext.isSeries24Member || securityContext.isSiteAdmin) {
        disableBusinessQuestionnaire();
        showSeries24Buttons();
        showSeries24Box();
        enableSeries24Comments();
        makeDocumentTypesRequired();
        showRedAsterixDocumentTypeSection();
        enableRiskRating();
        //enableDflow();
        showMarkAsFinalButton();
        showRedAsterikToInput($("[name=DFCRiskRating]"));
        showRedAsterikToInput($("#DealTeamBusinessReviewer"));
        showRedAsterikToInput($("#DCFEngagementTeam"));
        showRedAsterikToInput($("#Series24"));
        disableDeleteAttachmentOptionForNewQuestions();
        disableIncomeSectionAttachDocuments();
        
        mayorRole = true;
        if (!autoSaveEnabled) {
            showSeries24Box();
            showSaveButton("#series24Box .draftsub");
        }
        if (securityContext.isSiteAdmin && !securityContext.isSeries24Member) {
            removeS24ApproveButton("#btnS24Approve");
        }
    } else {
        readOnlyState.applyForAllInputExceptAdmSection();
        //Disbale xb form new document section
        disableCLientTypeXB();
        disableIncomeSectionAttachDocuments();
        disableDeleteAttachmentOptionForNewQuestions();
    }
}

function showFinalSubmissionForm(securityContext, reviewProcessContext, readOnlyState) {
    if(securityContext.isIndustryRepresentative || securityContext.isSiteAdmin || securityContext.isBusinessReviewer)
    {
        enableDflow();
    }
    else
    {
        disableDflow();
    }
    if (securityContext.isEngagementTeamMember || securityContext.isSiteAdmin) {
       enableAttachDocuments();
    }
    if (securityContext.canSubmitToS24() && reviewProcessContext.isDocumentReviewProcessComplete() == "Reviewed") {
        disableCLientTypeXB();
        disableDeleteAttachmentOptionForNewQuestions();
        if (!autoSaveEnabled) {
            $(".formsub").addClass('hidden');
            showFunctionalButtons();
            showSaveButton("#functional-buttons-save .draftsub");
        }
        readOnlyState.applyForAllInputExceptForRiskRating();
        showAttachmentSection();
        showSubmitToS24();
        disableIncomeSectionAttachDocuments();
    } else {
        readOnlyState.applyForAllInputExceptAdmSection();
        //Disbale xb form new document section
        disableCLientTypeXB();
        disableIncomeSectionAttachDocuments();
        disableDeleteAttachmentOptionForNewQuestions();
    }
   
    
}

function showRedAsterixDocumentTypeSection() {
    $(".document-type-section-span").removeClass("visibility-hidden")
}

function makeDocumentTypesRequired() {
    $("#required-DocumentTypes").attr("verify-document-types", "true");
}

function isMemberOfSpecificGroup(userEmail) {
    var isMember = false;
    if (userEmail != null) {
        isMember = userEmail.map(function(value){return value.toLowerCase()}).indexOf(currentUser.email.trim().toLowerCase()) > -1;
    }
    return isMember;
}

function showSaveButton(button) {
    var saveButton = $(button);
    saveButton.removeClass("hidden");
    saveButton.on('click', saveClick);
}

function removeS24ApproveButton(btnSelector) {
    var btnS24Approve = $(btnSelector);
    btnS24Approve.remove();
}

function saveClick(e) {
    e.preventDefault();
    $('.draftsub').off('click');
    $.when(doSave()).done(function () {
        showModalMessage(saveMessage);
    }).always(function () {
        $('.draftsub').on('click', saveClick);
    });
}

function isInGroup(userGroups, group) {
    var isFromGroup = userGroups.some(function (objVal) {
        //For NON-Prod env, DEV prefix must be considered.
        if (enviroment != "CrossBorderForm") {
            return "DEV " + group === objVal.Role;
        } else {
            return group === objVal.Role;
        }
    });
    return isFromGroup;
}

function _isDCFMemberInAdGroup(userGroups, group) {
    var isFromGroup = userGroups.some(function (objVal) {
        return objVal.userPrincipalName.trim().toLowerCase() === group.trim().toLowerCase();
    });
    return isFromGroup;
}

function authenticateUser(securityContext, formData) {
    var deferred = $.Deferred();
    if (securityContext.canAccessForm()) {
        // getUsersDataByFormId(id).done(function (data) {
            engagementTeam = formData.DCFEngagementTeam;
            dealTeamBusinessReviewer = formData.DealTeamBusinessReviewer;
            internationalMember=formData.InternationalMemberFirmTeam;
            internationalMemberDisplayName=formData.InternationalMemberFirmTeamDisplayNames;
            series24=formData.Series24;
            Series24DisplayNames=formData.Series24DisplayNames;
            fillDcfEngagementTeam(formData.DCFEngagementTeam);
            fillDealTeamBusinessReviewer(formData.DealTeamBusinessReviewer);
            fillInternationalMemberFirmTeam(formData.InternationalMemberFirmTeam);
            fillSeries24(formData.Series24);
            deferred.resolve(true);
        // }).fail(
        //     function (error) {
        //         showError();
        //         deferred.reject(false);
        //     });
    }
    else {
        redirectCustomList();
        $('#s4-workspace').css("pointer-events","auto");
        showModalMessage(editionNotAllowedMesssage);
    }
    return deferred.promise();
}

function getGroupRepresentative(dataRepresentative) {
    if (enviroment != "CrossBorderForm") {
        return (dataRepresentative.d.Representative.Title).split("DEV ")[1];
    }
    return dataRepresentative.d.Representative.Title;
}

function isAutosaveAllowedByWorkflowState(securityContext) {
    if (securityContext) {
        var autosaveAllowed = false;
        switch (securityContext.formGroup) {
            case "MemberFirmTeam":
                autosaveAllowed = securityContext.isAutoSaveEnabledOnFirstSubmission();
                break;
            case "IndustryRepresentative":
                autosaveAllowed = securityContext.isAutoSaveEnabledOnIndustryApproval();
                break;
            case "SecondSubmissionTeam":
                autosaveAllowed = securityContext.isAutoSaveEnabledOnSecondSubmission();
                break;
            case "BusinessQRMCompliance":
                autosaveAllowed = securityContext.isAutoSaveEnabledOnReviewInProgress();
                break;
            case "Series24":
                autosaveAllowed = securityContext.isAutoSaveEnabledOnSeries24Approval();
                break;
        }
        return autoSaveAllowed;
    }
    return true;
}

function getUserSecurityContext(dataRepresentative, userGroups, formData, formGroup, adUserGroup, Email) {
    var isRepresentative = false;
    var deferred = $.Deferred();
    // getIndustryRepresentative(industryId).done(function (dataRepresentative) {
        var isRepresentative = false;
        
            isRepresentative = isInGroup(userGroups, dataRepresentative);
        //}
        var isAdmin = isInGroup(userGroups, 'Cross Border Administrators');
        var isSeries24Member = isMemberOfSpecificGroup(formData.Series24);
        var isDealTeamBusinessReviewer = isMemberOfSpecificGroup(formData.DealTeamBusinessReviewer);
        var isQRMReviewer = isInGroup(userGroups, 'DCF_Crossborder_Prescreener');
        var isComplianceReviewer = isInGroup(userGroups, 'DCF_CrossBorder_Compliance');
        //var isDCFMember = isInGroup(userGroups,'DCF Compliance');
        var isDCFMember = _isDCFMemberInAdGroup(adUserGroup, Email);
        var isEngagementMember = isMemberOfSpecificGroup(formData.DCFEngagementTeam);
        var isMemberFirmMember = isMemberOfSpecificGroup(formData.InternationalMemberFirmTeam);

        //Add Roles in header - will be verified in authorizer lambda
        var userRoles = []
        if(isAdmin){
            userRoles.push('Cross Border Administrators');
            localStorage.setItem("IsXBAdmin",isAdmin);
        }
        if(isQRMReviewer){userRoles.push('DCF_Crossborder_Prescreener')};
        if(isComplianceReviewer){userRoles.push('DCF_CrossBorder_Compliance')};
        
        localStorage.setItem("UserRoles",userRoles.join());
        
        var securityContext = new UserSecurityContext(isDCFMember, isRepresentative, isAdmin, isSeries24Member, isDealTeamBusinessReviewer, isQRMReviewer, isComplianceReviewer, isEngagementMember, isMemberFirmMember, formGroup);
        deferred.resolve(securityContext);
    // }).fail(function (error) {
    //     showError();
    //     deferred.reject(false);
    // }).always(function (data) {
    // })
    return deferred.promise();
}

// function disableAllPeoplePicker() {
//     $.each($(".pp-editable-by-condition"), function () {
//         var ppName = $(this).attr("id").split("peoplePickerDiv")[1];
//         var peoplePicker = $('[id=peoplePickerDiv' + ppName + ']').find('div.sp-peoplepicker-topLevel');
//         $("#peoplePickerDiv" + ppName + "_TopSpan").find(".sp-peoplepicker-delImage").hide();
//         peoplePicker.addClass("sp-peoplepicker-topLevelDisabled");
//         peoplePicker.find("input.sp-peoplepicker-editorInput").prop('disabled', true);
//     })
// }

function disableAllChosenFields() {
    
    
        $("#DCFEngagementTeam,#DealTeamBusinessReviewer,#Series24").prop('disabled', true).trigger('chosen:updated');
       
    
   
    if (engagementTeam != undefined)
        fillDcfEngagementTeam(engagementTeam);
    if (dealTeamBusinessReviewer != undefined)
        fillDealTeamBusinessReviewer(dealTeamBusinessReviewer);
        if (series24 != undefined)
        fillSeries24(series24);

    if ($("#DCFEngagementTeam_chosen .search-choice-close").length > 0)
        $("#DCFEngagementTeam_chosen.search-choice-close").hide();

        //$(".chosen-search-input").attr("disabled", "disabled"); 
        $('#DCFEngagementTeam_chosen .search-choice-close').hide();
        $('#Series24_chosen .search-choice-close').hide();
        $('#DealTeamBusinessReviewer_chosen .search-choice-close').hide();
}

function enableAllChosenFields() {
    $(".chosen-select-no-results").prop('disabled', false).trigger('chosen:updated');
    if (engagementTeam != undefined)
        fillDcfEngagementTeam(engagementTeam);
    if (dealTeamBusinessReviewer != undefined)
        fillDealTeamBusinessReviewer(dealTeamBusinessReviewer);

        if(internationalMember!=undefined)
        {
            fillInternationalMemberFirmTeam(internationalMember);
        }  
        if (series24 != undefined)
        {
            fillSeries24(series24); 
        }
       
        if($("DCFEngagementTeam_chosen .search-choice-close.search-choice-close").length > 0)
        $("DCFEngagementTeam_chosen .search-choice-close.search-choice-close").hide();

}

function enableAllPeoplePicker() {
    $.each($(".pp-editable-by-condition"), function () {
        enablePeoplePicker($(this));
    })
}

function enableDCFAdminBoxPeoplePickers(autoSaveEnabled, mayorRole) {
  
    enableAllChosenFields();
    if (!autoSaveEnabled && !mayorRole) {
        showSaveDCFEngagementTeamBox();
        showSaveButton("#save-dcf-admin-box .draftsub");
    }
}

function enablePeoplePicker(ppSelector) {
    var ppName = ppSelector.attr("id").split("peoplePickerDiv")[1];
    var peoplePicker = $('[id=peoplePickerDiv' + ppName + ']').find('div.sp-peoplepicker-topLevel');
    $("#peoplePickerDiv" + ppName + "_TopSpan").find(".sp-peoplepicker-delImage").show();
    peoplePicker.removeClass("sp-peoplepicker-topLevelDisabled");
    peoplePicker.find("input.sp-peoplepicker-editorInput").prop('disabled', false);
    peoplePicker.find(".sp-peoplepicker-delImage").removeClass("hidden");
}

function UserSecurityContext(isDCFMember, isRepresentative, isAdmin, isSeries24Member, isBusinessReviewer, isQRMReviewer, isComplianceReviewer, isEngagementMember, isMemberFirmMember, formGroup) {
    this.isIndustryRepresentative = isRepresentative;
    this.isSiteAdmin = isAdmin;
    this.isSeries24Member = isSeries24Member;
    this.isBusinessReviewer = isBusinessReviewer;
    this.isQRMReviewer = isQRMReviewer;
    this.isComplianceReviewer = isComplianceReviewer;
    this.isEngagementTeamMember = isEngagementMember;
    this.isMemberFirmMember = isMemberFirmMember;
    this.isDCFMember = isDCFMember;
    this.formGroup = formGroup;
    this.isXBAdmin = function(){
        return this.isSiteAdmin;
    }
    this.canMarkDocumentsAsFinal = function () {
        return ['SecondSubmissionTeam', 'Series24', 'BusinessQRMCompliance'].indexOf(this.formGroup) !== -1 && (this.isSiteAdmin || this.isEngagementTeamMember || this.isBusinessReviewer);
    }
    this.canAccessForm = function () {
        return this.isSiteAdmin || this.isMemberFirmMember || this.isEngagementTeamMember || this.isIndustryRepresentative || this.isSeries24Member || this.isBusinessReviewer || this.isQRMReviewer || this.isComplianceReviewer;
    };
    this.canReview = function () {
        return this.formGroup == "BusinessQRMCompliance" && (this.isBusinessReviewer || this.isQRMReviewer || this.isComplianceReviewer || this.isSiteAdmin);
    };
    this.isQRMorCompliance = function () {
        return this.isQRMReviewer || this.isComplianceReviewer || this.isSiteAdmin;
    };
    this.canDeleteAttachments = function () {
        return this.formGroup == "SecondSubmissionTeam" && (this.isSiteAdmin || this.isMemberFirmMember || this.isEngagementTeamMember);
    };
    this.isPendingForIndustryRepresentative = function () {
        return this.formGroup == "IndustryRepresentative" && (this.isIndustryRepresentative || this.isSiteAdmin);
    };
    this.isPendingForSubmission = function () {
        return this.formGroup == "SecondSubmissionTeam" && (this.isMemberFirmMember || this.isSiteAdmin || this.isEngagementTeamMember);
    };
    this.isDCFDiscontinuing = function () {
        return this.formGroup == "SecondSubmissionTeam" && (this.isSiteAdmin || this.isEngagementTeamMember);
    };
    this.canClickOpenBoxesByBusiness = function () {
        return this.formGroup == "BusinessQRMCompliance" && (this.isBusinessReviewer || this.isSiteAdmin);
    };
    this.canClickOpenBoxesByQRM = function () {
        return this.formGroup == "BusinessQRMCompliance" && (this.isQRMReviewer || this.isSiteAdmin);
    };
    this.canClickOpenBoxesByCompliance = function () {
        return this.formGroup == "BusinessQRMCompliance" && (this.isComplianceReviewer || this.isSiteAdmin);
    };
    this.canAddAdditionalDocumentTypes = function () {
        return this.isEngagementTeamMember || this.isBusinessReviewer || this.isQRMReviewer || this.isComplianceReviewer || this.isSiteAdmin;
    };
    this.canSubmitToS24 = function () {
        return this.formGroup == "BusinessQRMCompliance" && (this.isEngagementTeamMember || this.isSiteAdmin);
    };
    this.isPendingForMemberFirmAfterReturnedByIndustry = function () {
        return this.formGroup == "MemberFirmTeam" && gblFormStatus == "Pending";
    }
    this.isAutoSaveEnabledOnFirstSubmission = function () {
        return this.isSiteAdmin || this.isMemberFirmMember;
    }
    this.isAutoSaveEnabledOnIndustryApproval = function () {
        return this.isSiteAdmin || this.isIndustryRepresentative;
    }
    this.isAutoSaveEnabledOnSecondSubmission = function () {
        return this.isSiteAdmin || this.isMemberFirmMember || this.isEngagementTeamMember;
    }
    this.isAutoSaveEnabledOnReviewInProgress = function () {
        return this.isSiteAdmin || this.canReview() || this.isEngagementTeamMember;
    }
    this.isAutoSaveEnabledOnSeries24Approval = function () {
        return this.isSiteAdmin || this.isSeries24Member;
    }
}

//-------------------------------Function for read only state-------------------------------

function ReadOnlyFormState() {
    this.deletePeoplePickerSelector = '.sp-peoplepicker-delImage';
    this.addPeoplePickerSelector = ".sp-peoplepicker-editorInput";
    this.inputsSelector = ".readonly-input";
    this.selectInputsSelector = ".readonly-select";
    this.lookupSelector = ".readonly-lookup";
    this.riskRatingSelector = ".risk-selector-option";
    this.checkboxSelector = ".readonly-checkbox";
    this.radioSelector = ".readonly-radio";
    this.radioSelectorClient= ".readonly-radio-client";

    var self = this;

    this._changeInputStateToReadOnly = function () {
       // $("input[type='file']").attr('disabled', 'disabled')
       // $("file").attr('disabled', 'disabled')
        $(".chosen-search-input").attr("disabled", "disabled"); 
        $('.search-choice-close').hide();
       // $('#ddlIncomeYear1,#ddlIncomeYear2,#ddlIncomeYear3').attr("disabled", "disabled"); 
       $('#ddlIncomeYear1,#ddlIncomeYear2,#ddlIncomeYear3').css("pointer-events","none");
        $.each($(self.inputsSelector), function () {
            $(this).prop("readonly", "readonly");
        })
        $.each($(self.selectInputsSelector), function () {
            var select = $(this);
            select.attr("onmousedown", "return false");
            select.attr("onkeydown", "return false");
        })
        $.each($(self.lookupSelector), function () {
            var lookup = $(this);
            lookup.prop("disabled", true).trigger("chosen:updated");
            $('#' + lookup.attr("id") + '_chosen').removeClass("chosen-disabled");
        })
        $.each($(self.checkboxSelector), function () {
            var checkbox = $(this);
            checkbox.attr("onclick", "return false");
        })

        $.each($(self.radioSelector), function () {
            var radio = $(this);
            var checkedInput = $("[name='" + radio.attr("name") + "']" + ":checked");
            radio.attr("onmousedown", "return false");
            radio.attr("onkeydown", "return false");
            radio.on("click", function (e) {
                e.returnValue = false;
                e.preventDefault();
                checkedInput.prop("checked", true);
                return false;
            })
        })

        $(self.deletePeoplePickerSelector).addClass("hidden");
        $(self.addPeoplePickerSelector).prop("disabled", "disabled");
        $("#Series24_chosen").removeClass("chosen-disabled"); 
        $("#Series24_chosen").css("pointer-events","none");
    
    }

    this._changeRiskRatingStateToReadOnly = function () {
        $.each($(self.riskRatingSelector), function () {
            var radio = $(this);
            var checkedInput = $("[name='" + radio.attr("name") + "']" + ":checked");
            radio.attr("onmousedown", "return false");
            radio.attr("onkeydown", "return false");
            radio.on("click", function (e) {
                e.returnValue = false;
                e.preventDefault();
                checkedInput.prop("checked", true);
                return false;
            })
        })
    }

    this.applyForAllForm = function () {
        this.applyForAllInput();
    }

    this.applyForAllInput = function () {
        this._changeInputStateToReadOnly(); //all inputs, select, peoplepickers
        this._changeRiskRatingStateToReadOnly();
        this.applyForDCFAdministrationSection();
    }

    this.applyForAllInputExceptAdmSection = function () {
        this._changeInputStateToReadOnly();
        this._changeRiskRatingStateToReadOnly();
    }

    this.applyForAllInputExceptForRiskRating = function () {
        this._changeInputStateToReadOnly();
    }

    this.applyForDCFAdministrationSection = function () {
        $.each($(".sp-peoplepicker-topLevelDisabled"), function () {
            $(this).addClass("sp-peoplepicker-topLevelReadonly");
            $(this).removeClass("sp-peoplepicker-topLevelDisabled");
        })
        $.each($(".risk-selector-option"), function () {
            $(this).prop("disabled", false);
        })
    }
}

function disableEnterpriseInputs() {
    var radioReadOnly = $(".readonly-radio");

    $.each(radioReadOnly, function () {
        var radio = $(this);
        var checkedInput = $("[name='" + radio.attr("name") + "']" + ":checked");
        radio.attr("onmousedown", "return false");
        radio.attr("onkeydown", "return false");
        radio.on("click", function (e) {
            e.returnValue = false;
            e.preventDefault();
            checkedInput.prop("checked", true);
            return false;
        })
    })
}
function disableCLientTypeXB() {
    var radioReadOnly = $(".readonly-radio-client");

    $.each(radioReadOnly, function () {
        var radio = $(this);
        var checkedInput = $("[name='" + radio.attr("name") + "']" + ":checked");
        radio.attr("onmousedown", "return false");
        radio.attr("onkeydown", "return false");
        radio.on("click", function (e) {
            e.returnValue = false;
            e.preventDefault();
            checkedInput.prop("checked", true);
            return false;
        });
    });
}
